<template>
  <span class="d-flex align-items-center justify-content-end m-20">
    <a
      v-if="helpData && helpKey"
      class="cursor-pointer helpDiv d-flex align-items-center justify-content-center"
      :href="helpData[helpKey]"
      target="_blank"
      ><i class="fa fa-question"></i>
    </a>
  </span>
</template>
  <script>
import OnlineHelpPOC from "@/modules/shared/scripts/OnlineHelpPOC.js";
export default OnlineHelpPOC;
</script>