import isNumber from "@/composables/fieldEntry";
import {
  numericRule,
  reasonForUpdateRequiredRule,
  requiredRule,
} from "@/composables/validationRules";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import { DROPDOWN_NO_DATA } from "@/constants/displayTexts";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import ReasonForUpdate from "@/modules/dot-details/components/ReasonForUpdate.vue";
import DatePicker from "@/modules/shared/components/DatePicker.vue";
import MonthYearPicker from "@/modules/shared/components/MonthYearPicker.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import VueAutoComplete from "@/modules/shared/components/VueAutoComplete.vue";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { computed, onMounted, ref, watch } from "vue";

export default {
  name: "PartIVPhysicalChar",
  emits: ["showBanner", "valueChanged"],
  props: {
    dotId: { default: null },
  },
  components: {
    PrimaryButton,
    SecondaryButton,
    DatePicker,
    VueAutoComplete,
    ReasonForUpdate,
    MonthYearPicker,
  },
  setup(props, context) {
    const reasonToUpdateList = ref([]);
    const crossingSurfaceList = ref([]);
    const smallestCrossingAngleRadioButtonList = ref([]);
    const trafficLanesCrossingRailroadList = ref([]);
    const intersectingRoadwayWithin500FeetList = ref([]);
    const yesOrNoList = ref(["Yes", "No"]);
    let PartIVDetails = ref(null);
    let isSaved = ref(false);
    const isFormValid = ref(false);
    const updatedDataFlagger = ref(false);
    const initialLoad = ref(false);
    const isInitialised = ref(false);
    const valueChanged = ref(false);
    let originalValue = ref(null);

    const isUserGradeCrossingAccess = ref({
      read: false,
      update: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const gradeCrossingAccessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.GRADE_CROSSING_RECORD_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.GRADE_CROSSING_RECORD_CRTUPD_ACCESS
      );
      isUserGradeCrossingAccess.value.read = readResponse || false;
      isUserGradeCrossingAccess.value.update = updateResponse || false;
    };
    onMounted(async () => {
      await gradeCrossingAccessCheck();
      if (isUserGradeCrossingAccess.value.read) {
        await getPartDetails();
      }
    });
    const userData = computed({
      get: () => {
        return sharedUserService.getSharedData();
      },
    });
    async function getPartDetails() {
      await axios
        .get(CONFIG_ENDPOINT.PARTIV + dotId.value)
        .then((response) => {
          PartIVDetails.value = response?.data;
          PartIVDetails.value.gradeCrossingPartFourDTO.reasonForUpdate =
            response?.data?.gradeCrossingPartFourDTO.reasonForUpdate;

          reasonToUpdateList.value = response?.data?.lookupData.reasonForUpdate;
          crossingSurfaceList.value =
            response?.data?.lookupData.crossingSurfaceOnMainTrack;
          trafficLanesCrossingRailroadList.value =
            response?.data?.lookupData.trafficLanesCrossingRailroad;
          intersectingRoadwayWithin500FeetList.value =
            response?.data?.lookupData.intersectingRoadwayWithin500Feet.slice(
              0,
              2
            );
          smallestCrossingAngleRadioButtonList.value =
            response?.data?.lookupData.smallestCrossingAngle;

          let temp;
          if (
            PartIVDetails.value.gradeCrossingPartFourDTO
              .crossingSurfaceInstallationDate != null
          ) {
            if (
              PartIVDetails.value.gradeCrossingPartFourDTO
                .crossingSurfaceInstallationDate?.length == 6
            ) {
              temp =
                PartIVDetails.value.gradeCrossingPartFourDTO.crossingSurfaceInstallationDate.substr(
                  2,
                  5
                ) +
                "-" +
                PartIVDetails.value.gradeCrossingPartFourDTO.crossingSurfaceInstallationDate.substr(
                  0,
                  2
                );
            } else if (
              PartIVDetails.value.gradeCrossingPartFourDTO
                .crossingSurfaceInstallationDate?.length == 5
            ) {
              temp =
                PartIVDetails.value.gradeCrossingPartFourDTO.crossingSurfaceInstallationDate.substr(
                  1,
                  4
                ) +
                "-0" +
                PartIVDetails.value.gradeCrossingPartFourDTO.crossingSurfaceInstallationDate.substr(
                  0,
                  1
                );
            }
            PartIVDetails.value.gradeCrossingPartFourDTO.crossingSurfaceInstallationDate =
              temp;
          }
          PartIVDetails.value.gradeCrossingPartFourDTO.userID =
            userData.value?.userID;
          initialLoad.value = true;
          originalValue.value = JSON.parse(
            JSON.stringify({ ...PartIVDetails.value })
          );

          setTimeout(() => {
            isInitialised.value = true;
          }, 1000);
        })
        .catch((err) => {});
    }
    const dotId = computed({
      get: () => {
        return props?.dotId;
      },
    });
    const saveClicked = () => {
      isSaved.value = true;
      let temp =
        PartIVDetails.value.gradeCrossingPartFourDTO
          .crossingSurfaceInstallationDate;
      PartIVDetails.value.gradeCrossingPartFourDTO.crossingSurfaceInstallationDate =
        PartIVDetails.value.gradeCrossingPartFourDTO.crossingSurfaceInstallationDate.substr(
          5,
          7
        ) +
        PartIVDetails.value.gradeCrossingPartFourDTO.crossingSurfaceInstallationDate.substr(
          0,
          4
        );
      axios
        .put(
          CONFIG_ENDPOINT.PARTIV + dotId.value,
          PartIVDetails.value.gradeCrossingPartFourDTO
        )
        .then((response) => {
          isInitialised.value = false;
          valueChanged.value = false;
          context.emit("ValueChanged", valueChanged.value);
          context.emit("showBanner", true);
        })
        .catch((err) => {
          context.emit("showBanner", false);
        });

      PartIVDetails.value.gradeCrossingPartFourDTO.crossingSurfaceInstallationDate =
        temp;
    };
    const cancelClicked = () => {
      isInitialised.value = false;
      valueChanged.value = false;
      context.emit("ValueChanged", valueChanged.value);
      getPartDetails();
      updatedDataFlagger.value = false;
    };

    const valueChange = (value) => {
      PartIVDetails.value.gradeCrossingPartFourDTO.reasonForUpdate = value;
    };

    const dateChange = (response) => {
      PartIVDetails.value.gradeCrossingPartFourDTO.crossingSurfaceInstallationDate =
        response;
    };

    const removeItem = (index) => {
      if (
        PartIVDetails.value.gradeCrossingPartFourDTO.crossingSurfaceOnMainTrack[
          index
        ] == "Other (Specify)"
      ) {
        PartIVDetails.value.gradeCrossingPartFourDTO.otherSpecify = "";
      }
      PartIVDetails.value.gradeCrossingPartFourDTO.crossingSurfaceOnMainTrack.splice(
        index,
        1
      );
    };
    function isLeftUpRightDownBackspaceDeleteTab(event) {
      let key = event.key || event.code;
      return (
        key === "ArrowLeft" ||
        key === 37 ||
        key === "ArrowUp" ||
        key === 38 ||
        key === "ArrowRight" ||
        key === 39 ||
        key === "ArrowDown" ||
        key === 40 ||
        key === "Backspace" ||
        key === 8 ||
        key === "Delete" ||
        key === 46 ||
        key === "Tab" ||
        key === 9
      );
    }
    function keydownAllowNumberOnly(event) {
      if (isLeftUpRightDownBackspaceDeleteTab(event)) {
        return;
      }

      let regex = /\d/;

      if (!regex.test(event.key)) {
        event.preventDefault();
        return false;
      }
    }

    function allowAlphanumericOnly(event) {
      const blockSpecialCharacterRegex = /^[a-z0-9\s]+$/i;
      let key = event.key ? event.key : String.fromCharCode(event.code);
      if (!blockSpecialCharacterRegex.test(key)) {
        event.preventDefault();
        return false;
      }
    }

    watch(
      () => [PartIVDetails.value],
      () => {
        if (initialLoad.value) {
          initialLoad.value = false;
        } else {
          updatedDataFlagger.value = true;
        }
        if (isInitialised.value && originalValue.value != PartIVDetails.value) {
          valueChanged.value = true;
          context.emit("ValueChanged", valueChanged.value);
        } else {
          valueChanged.value = false;
          context.emit("ValueChanged", valueChanged.value);
        }
      },
      { deep: true }
    );

    watch(
      () => [PartIVDetails.value?.gradeCrossingPartFourDTO
        ?.intersectingRoadwayWithin500Feet],
      () => {
       if (PartIVDetails.value.gradeCrossingPartFourDTO
        .intersectingRoadwayWithin500Feet == 'No'){
          PartIVDetails.value.gradeCrossingPartFourDTO
          .approximateDistanceToIntersectingRoadway = "";
        }
      },
      { deep: true }
    );


    const crossingSurfaceDateChange = (event) => {
      if (event?.target?.value) {
        PartIVDetails.value.gradeCrossingPartFourDTO.crossingSurfaceInstallationDate =
          event.target.value;
      } else if (event) {
        PartIVDetails.value.gradeCrossingPartFourDTO.crossingSurfaceInstallationDate =
          event;
      }
    };
    const isDateValidated = ref(false);
    const checkDateValidation = (value) => {
      isDateValidated.value = value;
    };
    const checkFormValidation = computed({
      get: () => {
        return isFormValid.value && isDateValidated.value;
      },
    });

    return {
      isDateValidated,
      checkDateValidation,
      checkFormValidation,
      isFormValid,
      dotId,
      PartIVDetails,
      isSaved,
      saveClicked,
      reasonToUpdateList,
      crossingSurfaceList,
      smallestCrossingAngleRadioButtonList,
      trafficLanesCrossingRailroadList,
      intersectingRoadwayWithin500FeetList,
      yesOrNoList,
      requiredRule,
      cancelClicked,
      dateChange,
      removeItem,
      VueAutoComplete,
      updatedDataFlagger,
      initialLoad,
      ReasonForUpdate,
      reasonForUpdateRequiredRule,
      valueChange,
      numericRule,
      isNumber,
      keydownAllowNumberOnly,
      isLeftUpRightDownBackspaceDeleteTab,
      allowAlphanumericOnly,
      crossingSurfaceDateChange,
      DROPDOWN_NO_DATA,
      valueChanged,
      isUserGradeCrossingAccess,
    };
  },
};
