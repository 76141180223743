<template>
  <div>
    <div class="m-10">
      <h2 class="f-size18 f-montserrat tableHeader">
        {{ tableHeader }}
      </h2>
    </div>
    <div class="d-flex justify-content-end">
      <div class="mr-5">
        <PrimaryButton
          v-if="showExportButton"
          :disabled="!tableData?.length"
          @buttonClicked="exportList(exportType)"
          :buttonText="'Export List'"
        ></PrimaryButton>
      </div>
    </div>
    <div class="indicator-text" v-if="instruction">
      <span>{{ instruction }}</span>
    </div>
    <div class="indicator-text" v-if="linkIndicator">
      <span><i class="fa fa-link"></i>&nbsp; {{ linkIndicator }}</span>
    </div>
    <v-table class="design-table">
      <thead>
        <tr>
          <th
            scope="col"
            v-for="column in columnData"
            :key="column.name"
            :style="{ 'min-Width': column?.columnWidth + '%' }"
            :class="{
              [column?.columnWidth ? 'w-' + column?.columnWidth : 'w-20']:
                column?.isAction,
              [column?.columnWidth ? 'w-' + column?.columnWidth : '']:
                column?.columnWidth,
            }"
          >
            {{ column.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-if="
            columnData[1].name == 'Engineering Authorization' &&
            tableData.length > 0
          "
        >
          <td v-for="(index, column) in columnData" :key="column.name">
            <div v-if="column === 1">
              <v-col style="display: flex; justify-content: center">
                <PrimaryButton
                  :disabled="!isSendAllButtonEnabled"
                  @buttonClicked="sendAllEngineeringAuthorizations(item, index)"
                  :buttonText="'SEND TO ALL RAILROADS'"
                ></PrimaryButton>
              </v-col>
            </div>
            <div v-if="column === 2">
              <v-col style="display: flex; justify-content: center">
                <PrimaryButton
                  :disabled="!isNTPSendAllButtonEnabled"
                  @buttonClicked="sendAllNTP(item, index)"
                  :buttonText="'SEND TO ALL RAILROADS'"
                ></PrimaryButton>
              </v-col>
            </div>
          </td>
        </tr>
        <tr
          v-for="(item, index) in tableData"
          :key="item.name"
          :style="{
            fontStyle: showItalics && item['completedDate'] ? 'italic' : '',
          }"
        >
          <td
            v-for="column in columnData"
            :key="column.key"
            :style="{ 'min-Width': column?.columnWidth + '%' }"
            :class="{
              'milestone-column': column?.isBold,
              [column?.columnWidth ? 'w-' + column?.columnWidth : 'w-20']:
                column?.isAction,
              [column?.columnWidth ? 'w-' + column?.columnWidth : '']:
                column?.columnWidth,
              'is-link': column?.isLink,
            }"
          >
            <div
              v-if="!column?.isAction"
              :style="{
                overflow: column?.isEllipsis ? 'hidden' : '',
                'white-space': column?.isEllipsis ? 'nowrap' : '',
                width: column?.isEllipsis ? '150px' : '',
                'text-overflow': column?.isEllipsis ? 'ellipsis' : '',
              }"
            >
              <span class="w-full" v-if="column?.isEllipsis"
                >{{ item?.[column.key] }}
                <v-tooltip
                  v-if="item?.[column.key]?.length > 23"
                  activator="parent"
                  location="start"
                  >{{ item?.[column.key] }}</v-tooltip
                >
              </span>
              <span
                :style="{
                  'justify-content': column?.alignLeft
                    ? 'start !important'
                    : 'end',
                  'text-align': column?.alignLeft ? 'left !important' : 'right',
                }"
                v-else-if="!column?.isLink && !column?.isEllipsis"
              >
                <span v-if="column.key === 'milepost'">|</span
                >{{ item?.[column.key] }}
                <span v-if="column.key === 'milepost'">|</span>
                <span v-if="column.secondLineKey">{{
                  item?.[column.secondLineKey]
                }}</span>
              </span>
              <span
                :style="{
                  display: 'flex',
                  'justify-content': column?.alignLeft
                    ? 'start !important'
                    : 'center',
                  'align-items': column?.alignLeft
                    ? 'left !important'
                    : 'center',
                  width: '100%',
                  height: '100%',
                }"
                v-if="column?.isLink"
                @click="
                  linkClicked(
                    column.key,
                    item?.[column.key] === 'streetViewLink'
                      ? item
                      : item?.[column.key]
                  )
                "
              >
                <span v-if="item?.[column.key] != 'streetViewLink'">{{
                  item?.[column.key]
                }}</span>
                <span
                  v-if="column?.showLinkIndicator && item?.showLinkIndicator"
                  class="indicator-text-row"
                >
                  <i class="fa fa-link"></i>
                </span>

                <i
                  v-if="item?.[column.key] === 'streetViewLink'"
                  class="fas fa-map-marker"
                  style="color: #076a21 !important; font-size: 24px"
                ></i>
              </span>
            </div>
            <div v-if="column?.isAction">
              <div
                class="d-flex align-items-center"
                v-if="column.key === 'sendEnable'"
              >
                <v-col cols="2"> </v-col>
                <v-col cols="6">
                  <span>{{ item.notificationDate }}</span>
                </v-col>
                <v-col cols="2">
                  <PrimaryButton
                    @buttonClicked="sendEngineeringAuthorization(item, index)"
                    :buttonText="'SEND'"
                    class="ml-10"
                    :disabled="!item.sendEnable"
                  ></PrimaryButton>
                </v-col>
              </div>
              <div
                class="d-flex align-items-center"
                v-if="column.key === 'ntpSendEnable'"
              >
                <v-col cols="2"> </v-col>
                <v-col cols="6">
                  <span>{{ item.ntpNotificationDate }}</span>
                </v-col>
                <v-col cols="2">
                  <PrimaryButton
                    @buttonClicked="sendNTP(item, index)"
                    :buttonText="'SEND'"
                    class="ml-10"
                    :disabled="!item.ntpSendEnable"
                  ></PrimaryButton>
                </v-col>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <IconButton
                  :disabled="disableActions"
                  v-if="
                    (!column?.addButtonCheckKey && column?.isRowAdd) ||
                    (column?.addButtonCheckKey &&
                      column?.isRowAdd &&
                      item[column?.addButtonCheckKey])
                  "
                  @buttonClicked="addRowClicked(item, index)"
                  :buttonText="
                    column?.addButtonText ? column?.addButtonText : 'Add'
                  "
                  :iconButton="true"
                  :iconName="
                    column?.addButtonCheckKey &&
                    column?.isRowAdd &&
                    item[column?.addButtonCheckKey] &&
                    column?.addButtonText === 'Remove'
                      ? 'mdi-delete'
                      : 'mdi-plus'
                  "
                ></IconButton>
                <IconButton
                  :disabled="disableActions"
                  v-if="
                    (!column?.otherButtonCheckKey && column?.isRowOther) ||
                    (column?.otherButtonCheckKey &&
                      column?.isRowOther &&
                      item[column?.otherButtonCheckKey])
                  "
                  @buttonClicked="otherRowClicked(item, index)"
                  :buttonText="
                    column?.otherButtonText ? column?.otherButtonText : 'Other'
                  "
                  :iconButton="true"
                  :iconName="'mdi-plus'"
                ></IconButton>
                <IconButton
                  v-if="column?.isEdit"
                  @buttonClicked="editClicked(item, index)"
                  :buttonText="'Edit'"
                  :iconButton="true"
                  :iconName="'mdi-pencil'"
                  :disabled="
                    disableActions || column?.isEdit
                      ? checkEditDisabled(item, column)
                      : false
                  "
                ></IconButton>
                <IconButton
                  v-if="
                    (!column?.deleteButtonCheckKey && column?.isDelete) ||
                    (column?.deleteButtonCheckKey &&
                      column?.isDelete &&
                      item?.[column?.deleteButtonCheckKey])
                  "
                  @buttonClicked="deleteClicked(index)"
                  :buttonText="
                    column?.deleteButtonText
                      ? column?.deleteButtonText
                      : 'Delete'
                  "
                  :disabled="
                    disableActions ||
                    (column?.isDeleteButtonDisabledKey &&
                      item[column?.isDeleteButtonDisabledKey])
                  "
                  :iconButton="true"
                  :iconName="'mdi-delete'"
                  class="pl-0"
                ></IconButton>
              </div>
            </div>
          </td>
        </tr>
        <tr v-if="tableData?.length == 0">
          <td
            class="text-center"
            :colspan="columnData?.length"
            :class="{ invalidErrorClass: invalidErrorClass }"
          >
            {{ errorMessage }}
          </td>
        </tr>
        <tr v-if="tableFooter?.length">
          <td
            v-for="column in tableFooter"
            :key="column.key"
            :colspan="column.colspan"
            :class="{ 'milestone-column': column?.isBold }"
          >
            {{ column.value }}
          </td>
        </tr>
        <tr v-if="showAddRow">
          <td :colspan="columnData.length">
            <SecondaryButton
              @buttonClicked="addClicked()"
              :buttonText="addButtonText"
              class="ml-10"
            ></SecondaryButton>
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>
<script>
import VueTable from "@/modules/shared/scripts/VueTable.js";
export default VueTable;
</script>
<style>
@import "@/modules/shared/assets/VueTable.scss";
@import "@/assets/css/style.scss";
.v-tooltip.v-overlay > .v-overlay__content {
  background: white;
  color: black;
}
</style>