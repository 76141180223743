<template>
  <v-form v-model="isFormValid" ref="partIForm">
    <v-card class="dot-part-form">
      <v-card-text>
        <div class="page-title">
          <h1>Location and Classification Information</h1>
        </div>
        <div></div>
        <div class="env-infp" v-if="PartIDetails">
          <div class="env-content">
            <v-row align="center">
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">1.</v-col>
                    <v-col cols="4">
                      <b class="textColumn">Railroad Company:</b>
                    </v-col>
                    <v-col cols="5">
                      {{
                        PartIDetails?.gradeCrossingPartOneDTO?.railroadCompany
                      }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">2.</v-col>
                    <v-col cols="4">
                      <b class="textColumn">State:</b>
                    </v-col>
                    <v-col cols="5">
                      {{ PartIDetails?.gradeCrossingPartOneDTO?.state }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">3.</v-col>
                    <v-col cols="4">
                      <b class="textColumn">County:</b>
                    </v-col>
                    <v-col cols="5">
                      {{ PartIDetails?.gradeCrossingPartOneDTO?.county }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">4.</v-col>
                    <v-col cols="4">
                      <b class="textColumn required"><span>City: </span></b>
                    </v-col>
                    <v-col cols="5">
                      <div class="d-flex justify-content-between w-100">
                        <v-select
                          class="w-20"
                          :rules="[requiredRule]"
                          v-model="
                            PartIDetails.gradeCrossingPartOneDTO.cityVicinity
                          "
                          :items="cityRadioButtonList"
                          outlined
                          :disabled="!isUserGradeCrossingAccess.update"
                        ></v-select>
                        <v-select
                          class="w-50 ml-10"
                          :rules="[requiredRule]"
                          v-model="
                            PartIDetails.gradeCrossingPartOneDTO.cityValue
                          "
                          :items="cityList"
                          outlined
                          :disabled="!isUserGradeCrossingAccess.update"

                        ></v-select></div></v-col></v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">5.</v-col>
                    <v-col cols="4">
                      <b class="textColumn required"
                        ><span>Street or Road Name: </span></b
                      >
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        :rules="[requiredRule]"
                        :maxlength="40"
                        v-model="
                          PartIDetails.gradeCrossingPartOneDTO.streetOrRoadName
                        "
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"

                      >
                      </v-text-field> </v-col></v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">6.</v-col>
                    <v-col cols="4">
                      <b class="textColumn required"
                        ><span>Highway Type &amp; No.: </span></b
                      >
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        :rules="[requiredRule]"
                        @keydown="allowAlphanumericOnly($event)"
                        :maxlength="40"
                        v-model="
                          PartIDetails.gradeCrossingPartOneDTO.highwayTypeAndNo
                        "
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"

                      >
                      </v-text-field> </v-col></v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2"></v-col>
                    <v-col cols="4">
                      <b class="textColumn required"
                        ><span> Block Number of Street or Road: </span></b
                      >
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        :rules="[requiredRule]"
                        :maxlength="6"
                        v-model="
                          PartIDetails.gradeCrossingPartOneDTO.blockNumber
                        "
                        @keydown="keydownAllowNumberOnly($event)"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"

                      >
                      </v-text-field> </v-col></v-row
                ></label>
              </v-col>
              <v-col cols="6"></v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">7.</v-col>
                    <v-col cols="4">
                      <b class="textColumn"
                        >Does another RR operate a separate track at
                        crossing?</b
                      >
                    </v-col>
                    <v-col cols="5">
                      {{
                        PartIDetails?.gradeCrossingPartOneDTO
                          ?.separateTrackAtCrossing
                      }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2"></v-col>
                    <v-col cols="4">
                      <b class="textColumn">
                        If Yes, specify RR(Upto 4 RR's):
                      </b>
                    </v-col>
                    <v-col cols="5">{{
                      PartIDetails?.gradeCrossingPartOneDTO
                        ?.separateTrackAtCrossing === "Yes"
                        ? PartIDetails?.gradeCrossingPartOneDTO
                            ?.separateSpecificRR
                        : ""
                    }}</v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">8. </v-col>
                    <v-col cols="4">
                      <b class="textColumn"
                        ><span
                          >Does another RR operate over your track at
                          crossing?</span
                        ></b
                      >
                    </v-col>
                    <v-col cols="5">
                      {{
                        PartIDetails?.gradeCrossingPartOneDTO
                          ?.otherRailroadsOperateOverTrack
                      }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2"></v-col>
                    <v-col cols="4">
                      <b class="textColumn"> If Yes, specify RR: </b>
                    </v-col>
                    <v-col cols="5"
                      >{{
                        PartIDetails?.gradeCrossingPartOneDTO
                          ?.otherRailroadsOperateOverTrack === "Yes"
                          ? PartIDetails?.gradeCrossingPartOneDTO
                              ?.otherOverYourSpecificRR
                          : ""
                      }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">9. </v-col>
                    <v-col cols="4">
                      <b class="textColumn">Railroad division or Region:</b>
                    </v-col>
                    <v-col cols="5">
                      {{
                        PartIDetails?.gradeCrossingPartOneDTO
                          ?.railroadDivisionOrRegion
                      }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">10. </v-col>
                    <v-col cols="4">
                      <b class="textColumn"
                        >Railroad Subdivision or District:</b
                      >
                    </v-col>
                    <v-col cols="5">
                      {{
                        PartIDetails?.gradeCrossingPartOneDTO
                          ?.railroadSubdivisionOrDistrict
                      }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">11. </v-col>
                    <v-col cols="4">
                      <b class="textColumn">Branch or Line Name:</b>
                    </v-col>
                    <v-col cols="5">
                      {{
                        PartIDetails?.gradeCrossingPartOneDTO?.branchOrLineName
                      }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row>
                    <v-col cols="2">12. </v-col>
                    <v-col cols="4">
                      <b class="textColumn"
                        >RR Milepost(prefix | nnnn.nnn | suffix):</b
                      >
                    </v-col>
                    <v-col cols="5">
                      |{{ PartIDetails?.gradeCrossingPartOneDTO?.rrMilepost }}|
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">13. </v-col>
                    <v-col cols="4">
                      <b class="textColumn">Line Segment:</b>
                    </v-col>
                    <v-col cols="5">
                      {{ PartIDetails?.gradeCrossingPartOneDTO?.lineSegmentId }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">14. </v-col>
                    <v-col cols="4">
                      <b class="textColumn">Nearest RR Timetable Station:</b>
                    </v-col>
                    <v-col cols="5">
                      {{
                        PartIDetails?.gradeCrossingPartOneDTO
                          ?.nearestRRTimetableStation
                      }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">15. </v-col>
                    <v-col cols="4">
                      <b class="textColumn">Parent RR:</b>
                    </v-col>
                    <v-col cols="5">
                      {{ PartIDetails?.gradeCrossingPartOneDTO?.parentRR }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">16. </v-col>
                    <v-col cols="4">
                      <b class="textColumn">Crossing Owner(if applicable):</b>
                    </v-col>
                    <v-col cols="5">
                      {{ PartIDetails?.gradeCrossingPartOneDTO?.crossingOwner }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">17. </v-col>
                    <v-col cols="4">
                      <b class="textColumn">Crossing Type:</b>
                    </v-col>
                    <v-col cols="5">
                      {{ PartIDetails?.gradeCrossingPartOneDTO?.crossingType }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">18. </v-col>
                    <v-col cols="4">
                      <b class="textColumn">Crossing Purpose:</b>
                    </v-col>
                    <v-col cols="5">
                      {{
                        PartIDetails?.gradeCrossingPartOneDTO?.crossingPurpose
                      }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">19. </v-col>
                    <v-col cols="4">
                      <b class="textColumn">Crossing Position:</b>
                    </v-col>
                    <v-col cols="5">
                      {{
                        PartIDetails?.gradeCrossingPartOneDTO?.crossingPosition
                      }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6"></v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">20. </v-col>
                    <v-col cols="4">
                      <b class="textColumn">Open Public Access:</b>
                    </v-col>
                    <v-col cols="5">
                      {{
                        PartIDetails?.gradeCrossingPartOneDTO?.openPublicAccess
                      }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">21. </v-col>
                    <v-col cols="4">
                      <b class="textColumn">Type of Train:</b>
                    </v-col>
                    <v-col cols="5">
                      {{ PartIDetails?.gradeCrossingPartOneDTO?.typeOfTrain }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">22. </v-col>
                    <v-col cols="4">
                      <b class="textColumn"
                        >Average Passenger Train Count Per Day:</b
                      >
                    </v-col>
                    <v-col cols="5">
                      {{
                        PartIDetails?.gradeCrossingPartOneDTO
                          ?.avgPassengerTrainCountPerDay
                      }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">23. </v-col>
                    <v-col cols="4">
                      <b class="textColumn"><span>Type of Land Use: </span></b>
                    </v-col>
                    <v-col cols="5">
                      <v-select
                        v-model="
                          PartIDetails.gradeCrossingPartOneDTO.typeOfLandUse
                        "
                        :items="landUseRadioButtonList"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"

                      ></v-select> </v-col></v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">24. </v-col>
                    <v-col cols="4">
                      <b class="textColumn"
                        >Is there an adjacent crossing with a separate
                        number?</b
                      >
                    </v-col>
                    <v-col cols="5">
                      {{
                        PartIDetails?.gradeCrossingPartOneDTO
                          ?.adjacentCrossingWithSeparateNumber
                      }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">25. </v-col>
                    <v-col cols="4">
                      <b class="textColumn">25. Quiet Zone (FRA Provided):</b>
                    </v-col>
                    <v-col cols="5">
                      {{ PartIDetails?.gradeCrossingPartOneDTO?.quietZone }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2"></v-col>
                    <v-col cols="4">
                      <b class="textColumn">
                        Date Established (FRA Provided):</b
                      >
                    </v-col>
                    <v-col cols="5">
                      {{
                        PartIDetails?.gradeCrossingPartOneDTO?.dateEstablished
                      }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">26. </v-col>
                    <v-col cols="4">
                      <b class="textColumn"><span>HSR Corridor ID: </span></b>
                    </v-col>
                    <v-col cols="5">
                      <v-select
                        v-model="
                          PartIDetails.gradeCrossingPartOneDTO.hsrCorridorId
                        "
                        :items="HSRCorridorList"
                        outlined                          
                        :disabled="!isUserGradeCrossingAccess.update"

                      ></v-select> </v-col></v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">27.</v-col>
                    <v-col cols="4">
                      <b class="textColumn"><span>Latitude: </span></b>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        :maxlength="10"
                        :rules="[latitudeRule]"
                        v-model="PartIDetails.gradeCrossingPartOneDTO.latitude"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"

                      >
                      </v-text-field> </v-col></v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">28. </v-col>
                    <v-col cols="4">
                      <b class="textColumn"><span>Longitude: </span></b>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        :maxlength="11"
                        :rules="[longitudeRule]"
                        v-model="PartIDetails.gradeCrossingPartOneDTO.longitude"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"

                      >
                      </v-text-field> </v-col></v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">29. </v-col>
                    <v-col cols="4">
                      <b class="textColumn"><span>Lat/Long Source:</span></b>
                    </v-col>
                    <v-col cols="5">
                      <v-select
                        class="select-fields"
                        v-model="
                          PartIDetails.gradeCrossingPartOneDTO.latLongSource
                        "
                        :items="PartIDetails.lookupData.latLongSource"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"

                      ></v-select>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">30.A.</v-col>
                    <v-col cols="4">
                      <b class="textColumn">Railroad Use:</b>
                    </v-col>
                    <v-col cols="5">
                      {{ PartIDetails?.gradeCrossingPartOneDTO?.railroadUseA }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">30.B. </v-col>
                    <v-col cols="4">
                      <b class="textColumn">Railroad Use:</b>
                    </v-col>
                    <v-col cols="5">
                      {{ PartIDetails?.gradeCrossingPartOneDTO?.railroadUseB }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">30.C. </v-col>
                    <v-col cols="4">
                      <b class="textColumn">Railroad Use:</b>
                    </v-col>
                    <v-col cols="5">
                      {{ PartIDetails?.gradeCrossingPartOneDTO?.railroadUseC }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">30.D. </v-col>
                    <v-col cols="4">
                      <b class="textColumn">Railroad Use:</b>
                    </v-col>
                    <v-col cols="5">
                      {{ PartIDetails?.gradeCrossingPartOneDTO?.railroadUseD }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">31.A. </v-col>
                    <v-col cols="4">
                      <b class="textColumn"
                        ><span>State Use/Type of Closure: </span></b
                      >
                    </v-col>
                    <v-col cols="5">
                      <v-select
                        v-model="PartIDetails.gradeCrossingPartOneDTO.stateUseA"
                        :items="PartIDetails.lookupData.stateUseTypeOfClosure"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"

                      ></v-select>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">31.B. </v-col>
                    <v-col cols="4">
                      <b class="textColumn"
                        ><span>State Use/ RR Owner: </span></b
                      >
                    </v-col>
                    <v-col cols="5">
                      <v-select
                        v-model="PartIDetails.gradeCrossingPartOneDTO.stateUseB"
                        :items="PartIDetails.lookupData.stateUseRRowner"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"

                      ></v-select>
                    </v-col> </v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">31.C </v-col>
                    <v-col cols="4">
                      <b class="textColumn"><span>State Use:</span></b>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        v-model="PartIDetails.gradeCrossingPartOneDTO.stateUseC"
                        outlined
                        :maxlength="20"
                        :disabled="!isUserGradeCrossingAccess.update"

                      >
                      </v-text-field> </v-col></v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">31.D </v-col>
                    <v-col cols="4">
                      <b class="textColumn">State Use:</b>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        :maxlength="20"
                        v-model="PartIDetails.gradeCrossingPartOneDTO.stateUseD"
                        outlined
                        :disabled="!isUserGradeCrossingAccess.update"

                      >
                      </v-text-field> </v-col></v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">32.A. </v-col>
                    <v-col cols="4">
                      <b class="textColumn"
                        ><span>Narrative(RailRoad Use): </span></b
                      >
                    </v-col>
                    <v-col cols="5" class="comment-box">
                      <v-textarea
                        @keydown="allowAlphanumericOnly($event)"
                        :maxlength="200"
                        v-model="
                          PartIDetails.gradeCrossingPartOneDTO
                            .railRoadUseNarrative
                        "
                        rows="4"
                        :disabled="!isUserGradeCrossingAccess.update"

                      >
                      </v-textarea> </v-col></v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">32.B. </v-col>
                    <v-col cols="4">
                      <b class="textColumn"
                        ><span> Narrative(State Use): </span></b
                      >
                    </v-col>
                    <v-col cols="5" class="comment-box">
                      <v-textarea
                        @keydown="allowAlphanumericOnly($event)"
                        :maxlength="200"
                        v-model="
                          PartIDetails.gradeCrossingPartOneDTO.stateUseNarrative
                        "
                        rows="4"
                        :disabled="!isUserGradeCrossingAccess.update"

                      >
                      </v-textarea> </v-col></v-row
                ></label>
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">33. </v-col>
                    <v-col cols="4">
                      <b class="textColumn"
                        >Emergency Notification Telephone No.:</b
                      >
                    </v-col>
                    <v-col cols="5">
                      {{
                        PartIDetails?.gradeCrossingPartOneDTO
                          ?.emergencyNotificationTelephone
                      }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">34. </v-col>
                    <v-col cols="4">
                      <b class="textColumn">Railroad Contact (Telephone):</b>
                    </v-col>
                    <v-col cols="5">
                      {{
                        PartIDetails?.gradeCrossingPartOneDTO
                          ?.railroadContactTelephone
                      }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="2">35. </v-col>
                    <v-col cols="4">
                      <b class="textColumn">State Contact (Telephone):</b>
                    </v-col>
                    <v-col cols="5">
                      {{
                        PartIDetails?.gradeCrossingPartOneDTO
                          ?.stateContactTelephone
                      }}
                    </v-col>
                  </v-row></label
                >
              </v-col>
            </v-row>
          </div>
        </div>
        <ReasonForUpdate
          :class="{ PartIDetails: true }"
          v-if="PartIDetails"
          :isFormValid="isFormValid"
          :reasonToUpdateList="reasonToUpdateList"
          :reasonForUpdate="
            PartIDetails?.gradeCrossingPartOneDTO?.reasonForUpdate
          "
          :isDisabled="!isUserGradeCrossingAccess.update"
          @valueChange="valueChange($event)"
          @saveClicked="saveClicked()"
          @cancelClicked="cancelClicked()"
          :updatedDataFlagger="updatedDataFlagger"
        ></ReasonForUpdate>
        <v-col
          cols="6"
          v-if="
            updatedDataFlagger &&
            !PartIDetails.gradeCrossingPartOneDTO.reasonForUpdate
          "
        >
          <label class="mpms-env-label"
            ><v-row
              ><v-col cols="2"> </v-col>
              <v-col cols="4">
                <b class="textColumn"><span></span></b>
              </v-col>
              <v-col cols="4">
                <div class="errorRow">
                  <div class="error">
                    <div>Required</div>
                  </div>
                </div>
              </v-col></v-row
            ></label
          >
        </v-col>
      </v-card-text>
    </v-card>
    <v-card> </v-card>
  </v-form>
</template>
<script>
import PartILocClassification from "@/modules/dot-details/scripts/PartILocClassification";
export default PartILocClassification;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>
