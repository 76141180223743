<template>
  <v-card class="p-64 v-card-text">
    <div class="allProjectsHeading">Manage Railroad Companies</div>
    <v-card class="p-64">
      <FeatureTable
        :headers="headers"
        :items="railRoadList"
        :showExportButton="true"
        :exportFilName="'Manage Railroads'"
        :showClearFilterButton="true"
        :tableHeader="''"
        :navigateToMpmsDetails="false"
        :clickable="true"
        :isRowClickedRouteEvent="true"
        :navigationKey="'mpmsNumber'"
        :itemsPerPage="paginationData?.items"
        :linkedPagination="true"
        :totalItems="paginationData?.total"
        :lookupData="lookupData"
        :oldMultipleFilter="multipleFilter"
        :hidePagination="true"
        :showFlagIndicator="true"
        :noDataAvailableText="noDataAvailableText"
        @updatePaginationPerPage="updatePaginationPerPage"
        @pageChange="pageChange"
        @sortUpdate="sortUpdate"
        @textFilterChange="textFilterChange"
        @multipleFiltersChange="multipleFiltersChange"
        @clearAllFilter="clearAllFilter"
        @rowClicked="editButtonClicked"
      ></FeatureTable>
      <div class="d-flex justify-content-start m-20">
        <PrimaryButton
          @buttonClicked="addButtonClicked"
          :buttonText="'Add Railroad'"
          :disabled="!isRailroadCompaniesManageAccess.update"
        ></PrimaryButton>
      </div>
    </v-card>
  </v-card>
  <LoadingOverlay :isLoading="loading"> </LoadingOverlay>
</template>
<script>
import ManageRailroadCompanies from "@/modules/gcmsmain/admin/submenu/scripts/ManageRailroadCompanies.js";
export default ManageRailroadCompanies;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>