import { CONFIG_ENDPOINT } from "@/constants/Endpoints";import MpmsLocationInfo from "@/modules/locationInformation/components/MpmsLocationInfo.vue";
import MpmsEnvironmentInfo from "@/modules/projectinformation/components/MpmsEnvironmentInfo.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import router from "@/router";
import { useUserStore } from "@/stores/userStore";
import axios from "axios";
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";

export default {
  components: {
    MpmsEnvironmentInfo,
    MpmsLocationInfo,
    PrimaryButton,
    SecondaryButton,
  },
  setup() {
    let projectDetails = ref(null);
    let tab = ref(null);
    let showTabs = ref(true);
    let create = ref(false);
    let srSec = ref(null);
    let county = ref(null);
    let municipality = ref(null);
    const route = useRoute();
    const projectId = route.params.id;
    const userStore = useUserStore();
    const user = computed(() => userStore.loggedInUser);
    onMounted(() => {
      axios
        .get(
          CONFIG_ENDPOINT.PROJECT_INFO_INFO +
            projectId +
            "?createGradeCrossing=true"
        )
        .then((response) => {
          projectDetails.value = response.data;
          console.log("projectDetails", projectDetails.value);
        })
        .catch((err) => {
          console.log(err);
          console.log("error projectDetails", projectDetails.value);
        });
    });
    const saveData = () => {
      axios
        .post(CONFIG_ENDPOINT.GCMS_CREATE_PROJECT + projectId, {
          mpmsNumber: projectId,
        })
        .then((response) => {
          window.scrollTo(0, 0);
          router.push({ path: `/GCMS/${projectId}` });
        })
        .catch((err) => {
          console.log(err);
        });
    };
    return {
      projectDetails,
      tab,
      projectId,
      showTabs,
      create,
      srSec,
      county,
      municipality,
      saveData,
    };
  },
};
