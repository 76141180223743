<template>
  <div class="allProjectsHeading">Manage Railroad Flagger Resources</div>

  <v-card class="p-64">
    <div class="w-100 d-flex justify-content-center">
      <div class="w-100 d-flex m-20 align-items-center justify-content-center">
        <span class="w-10 mr-5">Select Railroad:</span>
        <div :style="{ width: getDropdownItemsMaxLength() }">
          <v-autocomplete
            v-model="selectedRailroad"
            :items="railroadList"
            item-value="railroadCompanyId"
            item-title="name"
            :clearable="true"
            @update:modelValue="updateRailroad($event)"
            click:clear="updateRailroad($event)"
            :menu-props="{
              minWidth: getDropdownItemsMaxLength(),
              width: getDropdownItemsMaxLength(),
            }"
          >
          </v-autocomplete>
        </div>
      </div>
    </div>
    <ProjectIdentifierLegend></ProjectIdentifierLegend>
    <ExpandedTable
      v-if="lookupData"
      :headers="dataHeaders"
      :items="data"
      :showExportButton="true"
      :exportFilName="'ManageFlaggerAndRailroads'"
      :showClearFilterButton="true"
      :navigateToMpmsDetails="true"
      :clickable="false"
      :navigationKey="'mpmsNumber'"
      :hidePagination="true"
      :itemsPerPage="paginationData?.items"
      :linkedPagination="true"
      :totalItems="paginationData?.total"
      :lookupData="lookupData"
      :subHeaders="subHeaders"
      :subTableKey="'flaggerNeeds'"
      :expanded="expanded"
      :selectedRailroad="selectedRailroadLabel"
      :oldMultipleFilter="multipleFilter"
      :resetActiveKey="resetActiveKey"
      :showLegends="true"
      :passBlankAsNull="true"
      :isFixedHeaderWithScroll="true"
      :reduceSubTableWidth="true"
      @updatePaginationPerPage="updatePaginationPerPage"
      @pageChange="pageChange"
      @sortUpdate="sortUpdate"
      @textFilterChange="textFilterChange"
      @multipleFiltersChange="multipleFiltersChange"
      @clearAllFilter="clearAllFilter"
      @linkClicked="linkClicked($event)"
      @updateResetActiveKeyFlag="updateResetActiveKeyFlag($event)"
    >
    </ExpandedTable>
  </v-card>
  <LoadingOverlay :isLoading="loading"> </LoadingOverlay>
</template>
<script>
import ManageFlaggerAndRailroad from "@/modules/manageproject/scripts/ManageFlaggerAndRailroads.js";
export default ManageFlaggerAndRailroad;
</script>

<style>
@import "@/assets/css/style.scss";
</style>
