import { CONFIG_ENDPOINT } from "@/constants/Endpoints";import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import DialogComponent2 from "@/modules/manageproject/components/AddRailroadsContactsDialog.vue";
import DialogComponent from "@/modules/manageproject/components/RemoveRailroadModal.vue";
import FeatureTable from "@/modules/shared/components/FeatureTable.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import VueAutoComplete from "@/modules/shared/components/VueAutoComplete.vue";
import { useProjectServiceStore } from "@/stores/projectService";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { ADD_RAILROAD_NO_DATA_FOUND } from "../../../constants/displayTexts";

export default {
  components: {
    PrimaryButton,
    FeatureTable,
    DialogComponent,
    DialogComponent2,
    VueAutoComplete,
  },
  name: "addRailroads",
  props: ["dotId"],
  setup(props, { emit }) {
    const projectServiceStore = useProjectServiceStore();
    const dialogComponent = ref(null);
    const dialogComponentUpdateContacts = ref(null);
    const AddRailroadsContactsDialog = ref(null);
    const railroads = ref([]);
    const formattedRailroads = ref([]);
    const route = useRoute();
    const id = route.params.id;
    const crossings = ref([]);
    const railroadMap = ref({});
    const railroadList = ref([]);
    const railroadListObject = ref([]);
    const maxRailroadWidth = ref();
    const crossingFlag = ref(false);
    const additionalRailroadList = ref([]);
    const ownerAndOperatorList = ref([]);
    const isLoading = ref(true);
    const headers = ref([
      {
        title: "Railroad",
        sortType: "text",
        key: "railroadCompany",
        width: "32%!important",
      },
      {
        title: "Crossings",
        sortType: "text",
        key: "dotNumbers",
        sortable: false,
        width: "25%!important",
      },
      {
        title: "Contact(s)",
        sortType: "text",
        key: "existingContact",
        columnType: "array",
        arrayKeys: [
          { key: "fullName", isBold: true },
          { key: "email" },
          { key: "primaryPhone" },
          { key: "userID" },
          { key: "userRole" },
        ],
        sortable: false,
        showRemove: true,
        width: "20%!important",
      },
      {
        title: "Action",
        key: "action",
        sortable: false,
        columnType: "action",
        width: "10%",
        isOtherButton: true,
        otherButtonText: "Add Contacts",
      },
    ]);
    const paginationData = ref({
      page: 1,
      items: 15,
      total: 0,
    });

    const isUserAddRailroadAccess = ref({
      update: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const railroadAccessCheck = async () => {
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.ADD_RAILROAD_PROJECT_PAGE_CRTUPD_ACCESS
      );
      isUserAddRailroadAccess.value.update = updateResponse || false;
      isLoading.value = false;
    };

    onMounted(async () => {
      await railroadAccessCheck();
      if (isUserAddRailroadAccess.value.update) {
        getRailroadData();
      }
    });
    const getRailroadData = () => {
      axios.get(CONFIG_ENDPOINT.GET_RAILROADS + id).then((response) => {
        railroads.value = response.data;
        formattedRailroads.value = JSON.parse(JSON.stringify(response.data));
        console.log("formattedRailroads", formattedRailroads.value);
        formattedRailroads.value = formattedRailroads.value.map((railroad) => {
          let formattedDotNumbers = "";
          if (
            Array.isArray(railroad.dotNumbers) &&
            railroad.dotNumbers.length > 0
          ) {
            formattedDotNumbers = getFormattedDOTNumbers(railroad);
          } else if (
            typeof railroad.dotNumbers === "object" &&
            railroad.dotNumbers.label
          ) {
            formattedDotNumbers = railroad.dotNumbers.label;
          }
          return {
            ...railroad,
            selectedContacts: getFormatedSelectedContactsValue(railroad),
            dotNumbers: formattedDotNumbers,
          };
        });
        formattedRailroads.value = formattedRailroads.value.toSorted(
          dynamicSort("railroadCompany")
        );
        railroadMap.value = {};
        for (let RR of railroads.value) {
          railroadMap.value[RR.railroadCompany] = RR.dotNumbers?.map(
            (dot) => dot.dotNumber
          );
        }
        console.log("RAILROAD MAP");
        console.log(railroadMap.value);
        headers.value.forEach((header, index) => {
          headers.value[index] = {
            ...header,
            sortable: !checkFieldDisabled(header.key),
          };
        });
        paginationData.value = {
          page: 1,
          items: formattedRailroads.value.length,
          total: 0,
        };
        retrieveActiveRailroadList();
        associatedCrossingCheck();
      });
    };
    const getFormattedDOTNumbers = (railroad) => {
      return railroad.dotNumbers.map((dot) => dot.label).join(", ");
    };
    const getFormatedSelectedContactsValue = (railroad) => {
      return railroad.existingContact
        .map((contact) => contact.fullName)
        ?.join(", ");
    };
    const checkFieldDisabled = (key) => {
      if (key === "projectIdentifier") {
        return true;
      }
      let availableData = formattedRailroads.value.filter((data) => !data[key]);
      return availableData.length === formattedRailroads.value.length;
    };
    function dynamicSort(property) {
      return function (a, b) {
        if (a[property] < b[property]) {
          return -1;
        } else if (a[property] > b[property]) {
          return 1;
        } else {
          return 0;
        }
      };
    }

    async function retrieveActiveRailroadList() {
      let response = projectServiceStore.activeRailroadList;
      if (!response) {
        await projectServiceStore.retrieveActiveRailroadList();
        response = projectServiceStore.activeRailroadList;
      }
      console.log("RESPONSE");
      console.log(response);

      railroadListObject.value = response?.data;
      for (let index in railroadListObject.value) {
        railroadList.value.push(railroadListObject.value[index].name);
      }

      getCrossingData();
    }

    const getCrossingData = () => {
      axios.get(CONFIG_ENDPOINT.GET_CROSSINGS + id).then((response) => {
        maxRailroadWidth.value = railroadList.value.toSorted(
          (a, b) => b - a
        )[0]?.length;
        crossings.value = response.data.data.map((crossing) => {
          return {
            ...crossing,
            dotNumbers: getFormatedDotValue(crossing),
          };
        });
        console.log("railroad map 2");
        console.log(railroadMap.value);

        for (let cross in crossings.value) {
          additionalRailroadList.value[crossings.value[cross].dotNumbers] =
            crossings.value[cross].selectedAdditionalRailroad;
          ownerAndOperatorList.value[crossings.value[cross].dotNumbers] = 0;

          if (
            railroadMap.value.hasOwnProperty(crossings.value[cross].owner) &&
            (railroadMap.value[crossings.value[cross].owner].includes(
              crossings.value[cross].dotNumbers.replace("Nearby DOT# ", "")
            ) ||
              railroadMap.value[crossings.value[cross].owner].includes(
                crossings.value[cross].dotNumbers.replace("DOT# ", "")
              ))
          ) {
            ownerAndOperatorList.value[crossings.value[cross].dotNumbers] += 1;
          }
          if (
            railroadMap.value.hasOwnProperty(crossings.value[cross].operator) &&
            (railroadMap.value[crossings.value[cross].operator].includes(
              crossings.value[cross].dotNumbers.replace("Nearby DOT# ", "")
            ) ||
              railroadMap.value[crossings.value[cross].operator].includes(
                crossings.value[cross].dotNumbers.replace("DOT# ", "")
              ))
          ) {
            ownerAndOperatorList.value[crossings.value[cross].dotNumbers] += 1;
          }
        }

        console.log("OWNER OPERATOR LIST");
        console.log(ownerAndOperatorList.value);
        associatedCrossingCheck();
      });
    };
    const getFormatedDotValue = (crossing) => {
      return Array.isArray(crossing.dotNumbers)
        ? crossing.dotNumbers.map((dot) => dot.label).join(", ")
        : crossing.dotNumbers;
    };
    const associatedCrossingCheck = () => {
      crossingFlag.value = false;

      for (let dot in ownerAndOperatorList.value) {
        if (ownerAndOperatorList.value[dot] == 0) {
          crossingFlag.value = true;
        }
      }
    };
    const removeItem = (crossing, item) => {
      removeFromList(crossing.dotNumbers, item);
      crossing.selectedAdditionalRailroad.splice(
        crossing.selectedAdditionalRailroad.indexOf(item),
        1
      );
      associatedCrossingCheck();
    };
    const addToList = (dot, railroad) => {
      if (dot && typeof dot === "string") {
        const dotNumberValue = dot.replace(/^(DOT# |Nearby DOT# )/, "").trim();
        const endpoint =
          CONFIG_ENDPOINT.GET_RAILROADS + dotNumberValue + "/" + id;
        console.log("API Endpoint for addToList:", endpoint);
        axios
          .post(endpoint, [railroad])
          .then((response) => {
            getRailroadData();
          })
          .catch((error) => {
            console.error("API call failed:", error);
          });
      } else {
        console.error("Invalid dot object passed to addToList. dot:", dot);
      }
    };
    const updateList = (dot, railroad) => {
      if (dot && typeof dot === "string") {
        const dotNumberValue = dot.replace(/^(DOT# |Nearby DOT# )/, "").trim();
        const endpoint =
          CONFIG_ENDPOINT.GET_RAILROADS + dotNumberValue + "/" + id;
        console.log("API Endpoint for updateList:", endpoint);
        axios
          .post(endpoint, railroad)
          .then((response) => {
            getRailroadData();
          })
          .catch((error) => {
            console.error("API call failed:", error);
          });
      } else {
        console.error("Invalid dot object passed to updateList. dot:", dot);
      }
    };

    const removeFromList = (dot, railroad) => {
      if (dot && typeof dot === "string") {
        const dotNumberValue = dot.replace(/^(DOT# |Nearby DOT# )/, "").trim();
        const endpoint = `${
          CONFIG_ENDPOINT.GET_RAILROADS
        }${dotNumberValue}/${id}?railRoad=${encodeURIComponent(railroad)}`;
        console.log("API Endpoint for removeFromList:", endpoint);
        axios
          .delete(endpoint)
          .then((response) => {
            let index = railroadMap.value[railroad].indexOf(dotNumberValue);

            if (index > -1) {
              railroadMap.value[railroad].splice(index, 1);
            }
            console.log("post splice");
            console.log(railroadMap.value);

            getRailroadData();
          })
          .catch((error) => {
            console.error("API call failed:", error);
          });
      } else {
        console.error("Invalid dot object passed to removeFromList. dot:", dot);
      }
    };
    const otherButtonClicked = (event) => {
      console.log("railroadCompanyId", event);
      dialogComponentUpdateContacts.value
        .open("Add Contacts", event)
        .then((resolve) => {
          if (resolve) {
            if (resolve.success) {
              emit("BannerUpdate", {
                bannerType: "success",
                bannerMessage: "Saved Successfully.",
                showBanner: true,
              });
              window.scrollTo(0, 0);
            } else {
              emit("BannerUpdate", {
                bannerType: "error",
                bannerMessage:
                  resolve?.errorMsg?.response?.data?.businessMessage || "",
                showBanner: true,
                hasList: false,
                errorList: [],
              });
              window.scrollTo(0, 0);
            }
            getRailroadData();
          }
        });
    };
    const removeDialog = (dot, railroad, additional) => {
      let message1 = "";
      let message2 =
        "Are you sure you want to remove this railroad from the crossing ?";
      if (railroadMap.value[railroad]?.length == 1) {
        message1 =
          "This is the last Crossing associated with this Railroad. If you continue, you will remove the Railroad from the Project.";
      }
      if (additional) {
        if (additionalRailroadList.value[dot.dotNumbers].includes(railroad)) {
          dialogComponent.value.open(message1, message2).then((resolve) => {
            if (resolve) {
              removeItem(dot, railroad);
            }
          });
        } else {
          dot.selectedAdditionalRailroad.splice(
            dot.selectedAdditionalRailroad.indexOf(railroad),
            1
          );
        }
      } else {
        dialogComponent.value.open(message1, message2).then((resolve) => {
          if (resolve) {
            removeFromList(dot, railroad);
          }
        });
      }
    };
    const removeArrayItemClicked = (event) => {
      let payload = {
        ...event.arrayItem,
        railroadCompanyId:
          event.arrayItem?.railroadCompanyId || event.item?.railroadCompanyId,
      };
      console.log("event", payload);
      UpdateContact(payload);
    };
    const UpdateContact = (value) => {
      let payload = {
        railroadContactId: value.railroadContactId,
        projectRailroadInvolvementId: value.projectRailroadInvolvementId,
      };
      axios
        .delete(CONFIG_ENDPOINT.DELETE_RAILROAD_CONTACTS_REMOVE, {
          data: payload,
        })
        .then(() => {
          getRailroadData();
        })
        .catch((err) => {
          console.log(err);
        });
    };
    return {
      railroads,
      headers,
      getRailroadData,
      route,
      id,
      crossings,
      otherButtonClicked,
      AddRailroadsContactsDialog,
      removeItem,
      VueAutoComplete,
      addToList,
      railroadMap,
      removeFromList,
      railroadList,
      updateList,
      removeDialog,
      dialogComponent,
      crossingFlag,
      formattedRailroads,
      additionalRailroadList,
      ADD_RAILROAD_NO_DATA_FOUND,
      ownerAndOperatorList,
      maxRailroadWidth,
      dialogComponentUpdateContacts,
      paginationData,
      removeArrayItemClicked,
      isUserAddRailroadAccess,
      retrieveActiveRailroadList,
    };
  },
};
