import useDateField from "@/composables/dateField.js";import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import VueTable from "@/modules/shared/components/VueTable.vue";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

export default {
  components: { VueTable },
  name: "ConditionalRailroadCertifications",
  setup() {
    const router = useRouter();
    let ConditionalRailroadCertifications = ref([]);
    const { getFormattedDateStringNoTime } = useDateField();
    let errorMessage = ref(" ");
    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };
    let columnData = ref([
      {
        name: "MPMS #",
        key: "mpmsNumber",
        columnWidth: "5",
        isLink: true,
        alignLeft: true,
      },
      { name: "ECMS#", key: "ecmsNumber", columnWidth: "5" },
      { name: "SR-SEC", key: "srSec", columnWidth: "5" },
      { name: "Project Title", key: "projectTitle", columnWidth: "14" },
      { name: "District", key: "district", columnWidth: "5" },
      {
        name: "Certification Date",
        key: "certificationDate",
        columnWidth: "6",
      },
      { name: "PS&E Date", key: "estimatedpseDate", columnWidth: "6" },
      { name: "Let Date", key: "estimatedLetDate", columnWidth: "6" },
      { name: "NTP Date", key: "estimatedNTPDate", columnWidth: "6" },
      { name: "Conditional Type", key: "conditionalType", columnWidth: "10" },
      { name: "Reason", key: "reason", isEllipsis: true, columnWidth: "10" },
    ]);
    const isUserConditionalRailroadCertificationAccess = ref({
      read: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const railroadAccessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.VIEW_CONDITIONAL_CERTIFICATIONS_LIST_READ_ACCESS
      );

      isUserConditionalRailroadCertificationAccess.value.read =
        readResponse || false;
    };
    onMounted(async () => {
      await railroadAccessCheck();
      if (isUserConditionalRailroadCertificationAccess.value.read) {
        getConditionalData();
      }
    });
    async function getConditionalData() {
      axios
        .get(CONFIG_ENDPOINT.GET_CONDITIONAL_RAILROAD_CERTIFICATIONS)
        .then((response) => {
          console.log(response);
          ConditionalRailroadCertifications.value = response.data.data?.map(
            (certificate) => {
              return {
                ...certificate,
                certificationDate: getDate(certificate?.certificationDate),
                estimatedpseDate: getDate(certificate?.estimatedpseDate),
                estimatedLetDate: getDate(certificate?.estimatedLetDate),
                estimatedNTPDate: getDate(certificate?.estimatedNTPDate),
              };
            }
          );
          errorMessage.value = "No Data Available";
        })
        .catch((err) => {
          ConditionalRailroadCertifications.value = [];
          errorMessage.value = "No Data Available";
        });
    }

    const linkClicked = (event) => {
      console.log(event);
      router.push({
        path: `/GCMS/` + event.value,
      });
    };

    return {
      ConditionalRailroadCertifications,
      columnData,
      getDate,
      linkClicked,
      isUserConditionalRailroadCertificationAccess,
      errorMessage,
    };
  },
};
