<template>
  <v-dialog
    persistent
    @afterLeave="afterModalLeave"
    v-model="dialog"
    max-width="90vw"
    max-height="3000px"
  >
    <div class="dialogheading">
      <div class="w-100 d-flex justify-content-between">
        <div class="w-90 text-center f-size24">
          {{ title }}
        </div>
        <div>
          <v-icon @click="closeDialog" class="cursor-pointer" small
            >mdi-close
          </v-icon>
        </div>
      </div>
      <v-card>
        <v-card-text style="max-height: 80vh; overflow-y: scroll">
          <v-form v-model="isFormValid" class="pa1Form priorityForm">
            <div>
              <v-row align="center">
                <v-col cols="3"
                  ><div class="mpms-env-label required text-right">
                    <span><b>Project Priority:</b></span>
                  </div>
                </v-col>
                <v-col cols="7">
                  <v-container class="m-0 p-0"
                    ><v-radio-group inline v-model="formData.projectPriority">
                      <v-radio
                        :rules="[requiredRule]"
                        v-for="priority in priorityList"
                        :key="priority"
                        :label="priority"
                        :value="priority"
                      ></v-radio>
                    </v-radio-group>
                  </v-container>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3"
                  ><div class="mpms-env-label required text-right">
                    <span><b>Reason:</b></span>
                  </div>
                </v-col>
                <v-col cols="7">
                  <v-container class="white-textarea comment-box m-0 p-0"
                    ><v-textarea
                      :counter="150"
                      :maxlength="150"
                      :persistent-counter="true"
                      :rules="[requiredRule]"
                      v-model="formData.projectPriorityReason"
                      variant="solo"
                    ></v-textarea>
                    <div class="w-100 text-left">
                      <span
                        >{{
                          150 - (formData.projectPriorityReason?.length || 0)
                        }}
                        characters remaining.</span
                      >
                    </div>
                  </v-container>
                </v-col>
              </v-row>
              <div class="d-flex justify-content-center w-100">
                <div class="w-95" id="list-section">
                  <DocumentsList
                    :heading="'Project Priority Document List'"
                    :doctype="'/EMG_PR_DT'"
                    :id="id"
                    :updateDocumentList="updateDocumentList"
                    @updateDocumentListFlag="updateDocumentListFlag"
                    @filesLength="filesLength($event)"
                    @otherButtonClicked="updateButtonClicked($event)"
                  >
                  </DocumentsList>

                  <h2
                    class="f-size18 f-montserrat text-left mt-50"
                    style="
                      font-size: 18px;
                      padding-bottom: 0.5em;
                      color: #003e7e;
                    "
                  >
                    Upload Project Priority Documents
                  </h2>
                  <p
                    class="text-left"
                    style="padding-bottom: 1px; padding-left: 10px"
                  >
                    Maximum allowable file size is 500 MB.
                  </p>
                  <UploadScopingFieldViewDocument
                    v-if="!UpdateModalOpened"
                    @uploadFilesCall="uploadFiles"
                    :isDynamicDocumentType="false"
                    :staticDocumentTypeList="['Emergency Proclamation']"
                    :staticDocumentTypeValue="'Emergency Proclamation'"
                    :previewDocTypeUrl="'GET_DOCUMENT_TYPES_UPLOAD_LIST'"
                    @valueChangedEvent="valueChangedEvent($event)"
                  ></UploadScopingFieldViewDocument>
                </div>
              </div>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <div class="action">
            <PrimaryButton
              @buttonClicked="saveData"
              :disabled="
                !formData.projectPriority ||
                !formData.projectPriorityReason ||
                !uploadedFilesLength
              "
              :buttonText="'Save'"
            ></PrimaryButton>
            <SecondaryButton
              @buttonClicked="closeDialog"
              :buttonText="'Cancel'"
              class="ml-2"
            ></SecondaryButton>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
  <LoadingOverlay :isLoading="loading"> </LoadingOverlay>
  <DialogComponent ref="ralroadEstimatesDocumentsDialogRef"></DialogComponent>
</template>
<script>
import PriorityProjectDialog from "@/modules/projectinformation/scripts/PriorityProjectDialog.js";
export default PriorityProjectDialog;
</script>
<style>
@import "@/modules/projectinformation/assets/projectinfostyle.scss";
@import "@/assets/css/style.scss";
</style>
