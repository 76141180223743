import { requiredRule } from "@/composables/validationRules";import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import axios from "axios";
import { computed, ref, watch } from "vue";
export default {
  name: "AddComments",
  props: [
    "projectComments",
    "commentType",
    "sectionTitle",
    "commentMaxLength",
    "id",
    "saveClicked",
    "cancelClicked",
    "isUserCommentAccess",
  ],
  emits: ["commentUpdate", "addComment", "saveClicked", "cancelClicked"],
  setup(props, context) {
    const isUserCommentAccess = ref(props?.isUserCommentAccess);
    watch(
      () => [props?.saveClicked],
      () => {
        if (props?.saveClicked && comment.value && isCommentsFormValid.value) {
          addComment();
        }
      },
      { deep: true }
    );
    watch(
      () => [props?.isUserCommentAccess],
      () => {
        isUserCommentAccess.value = props?.isUserCommentAccess;
      },
      { deep: true }
    );
    watch(
      () => [props?.cancelClicked],
      () => {
        if (props?.cancelClicked && comment.value) {
          comment.value = "";
        }
      },
      { deep: true }
    );
    const userData = computed({
      get: () => {
        return sharedUserService.getSharedData();
      },
    });
    const isCommentsFormValid = ref(false);
    const comment = ref("");
    const projectComments = computed({
      get: () => {
        return props?.projectComments;
      },
    });
    const commentType = computed({
      get: () => {
        return props?.commentType;
      },
    });
    const sectionTitle = computed({
      get: () => {
        return props?.sectionTitle;
      },
    });
    const commentMaxLength = computed({
      get: () => {
        return props?.commentMaxLength;
      },
    });
    const id = computed({
      get: () => {
        return props?.id;
      },
    });
    const addComment = () => {
      if (commentType.value === "Project Comments") {
        axios
          .post(CONFIG_ENDPOINT.PROJECT_INFO_COMMENTS + id.value, {
            comment: comment.value,
            commentType: "PRJCT_CMNT",

            userRole: userData.value?.userRole,
            username: userData.value?.username,
          })
          .then((response) => {
            comment.value = "";
            context.emit("addComment", response.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axios
          .post(CONFIG_ENDPOINT.PROJECT_INFO_SCOPEFIELD_COMMENTS + id.value, {
            comment: comment.value,
            commentType: "PROJ_SCOPE",
            userRole: userData.value?.userRole,
            username: userData.value?.username,
          })
          .then((response) => {
            comment.value = "";
            context.emit("addComment", response.data);
          })
          .catch((err) => {
            comment.value = "";
            console.log(err);
          });
      }
    };
    const updateCommentValue = (value) => {
      context.emit("commentUpdate", {
        commentType: commentType.value,
        comment: comment.value,
      });
    };
    return {
      isCommentsFormValid,
      comment,
      addComment,
      projectComments,
      commentType,
      sectionTitle,
      commentMaxLength,
      id,
      requiredRule,
      userData,
      updateCommentValue,
      isUserCommentAccess,
    };
  },
};
