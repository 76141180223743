import useFieldEntry from "@/composables/fieldEntry";import {
  latitudeRule,
  longitudeRule,
  numericRule,
  reasonForUpdateRequiredRule,
  requiredRule,
} from "@/composables/validationRules";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import ReasonForUpdate from "@/modules/dot-details/components/ReasonForUpdate.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { computed, onMounted, ref, watch } from "vue";

import { DROPDOWN_NO_DATA } from "@/constants/displayTexts";
export default {
  name: "PartILocClassification",
  emits: ["showBanner", "ValueChanged"],
  props: {
    dotId: { default: null },
    showBanner: { default: false },
  },
  components: { PrimaryButton, SecondaryButton, ReasonForUpdate },
  setup(props, context) {
    const reasonToUpdateList = ref([]);
    const cityList = ref([]);
    const cityRadioButtonList = ref([]);
    const landUseRadioButtonList = ref([]);
    const HSRCorridorList = ref([]);
    let PartIDetails = ref(null);
    let isSaved = ref(false);
    const isFormValid = ref(false);
    const updatedDataFlagger = ref(false);
    const initialLoad = ref(false);
    const { keydownAllowNumberOnly } = useFieldEntry();
    const { allowAlphanumericOnly } = useFieldEntry();
    const isInitialised = ref(false);
    const valueChanged = ref(false);
    let originalValue = ref(null);

    const isUserGradeCrossingAccess = ref({
      read: false,
      update: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const gradeCrossingAccessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.GRADE_CROSSING_RECORD_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.GRADE_CROSSING_RECORD_CRTUPD_ACCESS
      );
      isUserGradeCrossingAccess.value.read = readResponse || false;
      isUserGradeCrossingAccess.value.update = updateResponse || false;
    };

    onMounted(async () => {
      await gradeCrossingAccessCheck();
      if (isUserGradeCrossingAccess.value.read) {
        await getPartDetails();
      }
    });
    const userData = computed({
      get: () => {
        return sharedUserService.getSharedData();
      },
    });
    async function getPartDetails() {
      await axios
        .get(`${CONFIG_ENDPOINT.PARTI}${dotId.value}`)
        .then((response) => {
          PartIDetails.value = null;
          reasonToUpdateList.value = null;
          PartIDetails.value = response?.data;
          PartIDetails.value.gradeCrossingPartOneDTO.reasonForUpdate =
            response?.data?.gradeCrossingPartOneDTO.reasonForUpdate;
          reasonToUpdateList.value = response?.data?.lookupData.reasonForUpdate;
          cityRadioButtonList.value = response?.data?.lookupData.cityVicinity;
          cityList.value = response?.data?.lookupData.cityValues;
          landUseRadioButtonList.value =
            response?.data?.lookupData.typeOfLandUse.filter(
              (value) => value && value != ""
            );
          HSRCorridorList.value = response?.data?.lookupData.hsrCorridorId;
          PartIDetails.value.gradeCrossingPartOneDTO.emergencyNotificationTelephone =
            getMaskedPhoneNumber(
              PartIDetails.value.gradeCrossingPartOneDTO
                .emergencyNotificationTelephone
            );
          PartIDetails.value.gradeCrossingPartOneDTO.railroadContactTelephone =
            getMaskedPhoneNumber(
              PartIDetails.value.gradeCrossingPartOneDTO
                .railroadContactTelephone
            );
          PartIDetails.value.gradeCrossingPartOneDTO.stateContactTelephone =
            getMaskedPhoneNumber(
              PartIDetails.value.gradeCrossingPartOneDTO.stateContactTelephone
            );
          initialLoad.value = true;
          PartIDetails.value.gradeCrossingPartOneDTO.userID =
            userData.value?.userID;

          originalValue.value = JSON.parse(
            JSON.stringify({ ...PartIDetails.value })
          );

          setTimeout(() => {
            isInitialised.value = true;
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    const dotId = computed({
      get: () => {
        return props?.dotId;
      },
    });
    const getMaskedPhoneNumber = (value) => {
      if (value) {
        let number =
          "(" +
          value.substring(0, 3) +
          ") " +
          value.substring(3, 6) +
          " - " +
          value.substring(6, 10);
        return number;
      } else {
        return value;
      }
    };
    const valueChange = (value) => {
      PartIDetails.value.gradeCrossingPartOneDTO.reasonForUpdate = value;
    };
    const saveClicked = () => {
      isSaved.value = true;
      axios
        .put(
          CONFIG_ENDPOINT.PARTI + dotId.value,
          PartIDetails.value.gradeCrossingPartOneDTO
        )
        .then(() => {
          isInitialised.value = false;
          valueChanged.value = false;
          context.emit("showBanner", true);
        })
        .catch((err) => {
          context.emit("showBanner", false);
        });
    };
    const cancelClicked = () => {
      getPartDetails();
      updatedDataFlagger.value = false;

      isInitialised.value = false;
      valueChanged.value = false;
      context.emit("ValueChanged", valueChanged.value);
    };

    watch(
      () => [PartIDetails.value],
      () => {
        if (initialLoad.value) {
          initialLoad.value = false;
        } else {
          updatedDataFlagger.value = true;
        }
        if (isInitialised.value && originalValue.value != PartIDetails.value) {
          valueChanged.value = true;
          context.emit("ValueChanged", valueChanged.value);
        } else {
          valueChanged.value = false;
          context.emit("ValueChanged", valueChanged.value);
        }
      },
      { deep: true }
    );
    return {
      isFormValid,
      dotId,
      PartIDetails,
      isSaved,
      saveClicked,
      reasonToUpdateList,
      cityList,
      cityRadioButtonList,
      landUseRadioButtonList,
      HSRCorridorList,
      requiredRule,
      latitudeRule,
      longitudeRule,
      cancelClicked,
      allowAlphanumericOnly,
      keydownAllowNumberOnly,
      numericRule,
      ReasonForUpdate,
      valueChange,
      reasonForUpdateRequiredRule,
      updatedDataFlagger,
      initialLoad,
      DROPDOWN_NO_DATA,
      valueChanged,
      isUserGradeCrossingAccess,
    };
  },
};
