export const requiredRule = (value) => !!value || "Required";
export const arrayRequiredRule = (value) => !!value?.length || "Required";
export const numericRule = (value) =>
  /^\d\d*$/.test(value) || "Must be numeric";
export const wholeOrDecimal = (value) =>
  /^\d*(\.\d{0,2})?$/.test(value) || "Decimal accepted upto 2 digit";
export const isValidDate = (value) =>
  !value || this.isValidLocalDate(value) || "Invalid Date.";
export const minLengthRule1 = (value) =>
  value?.length > 0 || "At least one Activity must be selected";
export const maxLengthRule1 = (value) => value?.length <= 2 || "Limit is 99";
export const maxLengthRule2 = (value) => value?.length <= 3 || "Limit is 999";
export const maxLengthRule999 = (value) =>
  value?.length <= 999 || "Limit is 999";
export const minimumRule = (value) => parseInt(value) > 0 || "Minimum is 1";
export const minimumSerialRule = (value) =>
  value?.length < 15 || "Minimum is 14";
export const greaterThanEqualToZero = (value) =>
  value?.length > 0 || "Required";
export const mpmsRequiredRule = (value) => !!value || "MPMS number is required";
export const mpmsLengthRule = (value) =>
  (value && value?.length <= 9) || "Maximum characters allowed is 9";
export const agreementLengthRule = (value) =>
  (value == "" || value == null || value?.length <= 20) || "Maximum characters allowed is 20";
export const reasonForUpdateRequiredRule = (value) => !!value || "Required";
export const minMax15 = (value) => value?.length == 15 || "Invalid #Serial";
export const tenBillionRule = (value) =>
  (value >= 0 && value <= 10000000000) || "Max is 10,000,000,000";

export const railroadEstimateRule = (value) =>
  (parseInt(value.replaceAll('$','').replaceAll(',', '')) >= 0 && parseInt(value.replaceAll('$','').replaceAll(',', '')) <= 999999999.99) || "Max is $999,999,999.99";

export const latitudeRule = (value) =>
  /^[+-]?(?:90(?:(?:\.0{1,7})?)|(?:\d|[1-8]\d)(?:(?:\.\d{1,7})?))$/.test(
    value
  ) || "Invalid Latitude";
export const longitudeRule = (value) =>
  /^[+-]?(?:180(?:(?:\.0{1,7})?)|(?:\d|[1-9]\d|1[0-7]\d)(?:(?:\.\d{1,7})?))$/.test(
    value
  ) || "Invalid Longitude";
export const validDateFutureRule = (value) => {
  if (value && new Date(value) > new Date()) {
    return "PA1Call Date cannot be in the future";
  }
};
export const validDateFutureDate_Inspections = (value) => {
  if (value && new Date(value) > new Date()) {
    return "Inspection Date cannot be in the future";
  }
};
export const validateEmail = (value) =>
  !value ||
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+ "))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  ) ||
  "Invalid Email";
export const phoneRule = (value) =>
  value.length == 0 ||
  (value && value.length == 14) ||
  "Phone number must be 10 digits";
export const zipRule = (value) =>
  (value &&
    (value.length == 5 || value.length == 10) &&
    /\d{5}(?:-\d{4})?$/.test(value)) ||
  "ZIP must be 5 or 9 digits";

export const isValidLocalDate = (date) => {
  if (!date || date?.length != 10) {
    return false;
  }
  let selectedDate = new Date(date).setHours(0, 0, 0, 1);
  let maxDate = new Date().setHours(0, 0, 0, 1);
  return moment(date, "YYYY-MM-DD", true).isValid() && selectedDate < maxDate;
};
export const validPUCDocketNumber = (value) =>
  !value ||
  /^[a-zA-Z]-\d{6,12}$/.test(value) ||
  /^[a-zA-Z]-\d{4}-\d{1,7}$/.test(value) ||
  "PUC Docket # must be between 8-14 characters and in the format A-99999999 or A-9999-9999999.";

export const minConditionLength = (value) =>
  value?.length > 0 || "At least one Condition must be selected";
