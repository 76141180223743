import UploadScopingFieldViewDocument from "@/modules/scoping-field-view/components/UploadScopingFieldViewDocument.vue";
import DatePicker from "@/modules/shared/components/DatePicker.vue";
import DocumentsList from "@/modules/shared/components/DocumentsList.vue";
import LoadingOverlay from "@/modules/shared/components/LoadingOverlay.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import SecondaryButton from "@/modules/shared/components/SecondaryButton.vue";
import { useDocumentStore } from "@/stores/document";
import { reactive, ref, toRefs } from "vue";
import { CONFIG_ENDPOINT } from "../../../constants/Endpoints";
export default {
  components: {
    PrimaryButton,
    SecondaryButton,
    DatePicker,
    UploadScopingFieldViewDocument: UploadScopingFieldViewDocument,
    DocumentsList,
    LoadingOverlay,
  },
  props: {
    id: { type: Number, default: null },
    item: Object, // Item to be edited (if in 'edit' mode)
    projectDetails: Object,
    pa1Details: [],
  },
  name: "RailroadWorkSummaryDocumentDialog",
  setup(props) {
    let isFormValid = ref(false);
    let priorityList = ref(["Emergency", "Critical", "Normal"]);
    const documentStore = useDocumentStore();
    const filesList = ref([]);
    const loading = ref(false);
    const state = reactive({
      dialog: false,
      resolve: null,
      reject: null,
      title: null,
      id: null,
      railroadValue: null,
      railroadList: [],
      formattedDate: new Date(),
      isUpdate: false,
      updateEvent: null,
      dotNumbersList: [],
    });

    const open = (
      title,
      id,
      railroadValue,
      railroadList,
      isUpdate,
      updateEvent, 
      dotNumbersList,
    ) => {
      state.dialog = true;
      state.title = title;
      state.id = id;
      state.railroadValue = railroadValue;
      state.railroadList = railroadList;
      state.isUpdate = isUpdate;
      state.updateEvent = updateEvent;
      state.dotNumbersList = dotNumbersList;
      return new Promise((resolve, reject) => {
        state.resolve = resolve;
        state.reject = reject;
      });
    };
    const saveData = () => {
      state.resolve(true);
      state.dialog = false;
    };

    let valueChanged = ref(false);
    const closeDialog = () => {
      if (valueChanged.value) {
        const answer = window.confirm(
          "You have unsaved data. Are you sure to leave the page?"
        );
        if (answer) {
          valueChanged.value = false;
          state.resolve(true);
          state.dialog = false;
        }
      } else {
        valueChanged.value = false;
        state.resolve(true);
        state.dialog = false;
      }
    };
    const afterModalLeave = () => {
      valueChanged.value = false;
    };
    const valueChangedEvent = (event) => {
      valueChanged.value = event;
    };
    window.onbeforeunload = function () {
      if (valueChanged.value) {
        return "You have unsaved data. Are you sure to leave the page?";
      }
    };

    const uploadFiles = async (files) => {
      loading.value = true;
      if (state.isUpdate) {
        let payload = {
          docType: state.updateEvent.documentType,
          docID: state.updateEvent.escDocumentId,
          mpmsNumber: state.updateEvent.mpmsNumber,
          railRoadName: state.updateEvent?.railRoadName,
        };
        let endpoint = CONFIG_ENDPOINT.UPDATE_VERSION;
        document.getElementById("list-section")?.scrollIntoView();
        await documentStore.uploadDocuments(
          state.id,
          files,
          "",
          endpoint,
          true,
          false,
          true,
          true,
          payload
        );
      } else {
        let docType = "Emergency Proclamation";
        let endpoint = CONFIG_ENDPOINT.UPLOAD_SCOPING_DOCUMENTS;
        document.getElementById("list-section").scrollIntoView();
        await documentStore.uploadDocuments(
          state.id,
          files,
          docType,
          endpoint,
          true,
          true,
          true
        );
      }
      loading.value = false;
      saveData();
    };

    

    return {
      ...toRefs(state),
      isFormValid,
      saveData,
      closeDialog,
      open,
      priorityList,
      uploadFiles,
      filesList,
      loading,
      afterModalLeave,
      valueChangedEvent,
    };
  },
};
