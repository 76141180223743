<template>
  <v-dialog
    persistent
    @afterLeave="afterModalLeave"
    v-model="dialog"
    max-width="90vw"
    max-height="3000px"
  >
    <div class="dialogheading">
      <div class="w-100 d-flex justify-content-between">
        <div class="w-90 text-center f-size24">
          {{ title }}
        </div>
        <div>
          <v-icon @click="closeDialog" class="cursor-pointer" small
            >mdi-close
          </v-icon>
        </div>
      </div>
      <v-card>
        <v-card-text style="max-height: 80vh; overflow-y: scroll">
          <v-form v-model="isFormValid" class="pa1Form priorityForm">
            <v-grid>
              <v-row justify="center">
                <v-col cols="10">
                  <h2
                    class="f-size18 f-montserrat text-left mt-50"
                    style="
                      font-size: 18px;
                      padding-bottom: 0.5em;
                      color: #003e7e;
                    "
                    v-if="!isUpdate"
                  >
                    Upload Railroad Work Summary Documents
                  </h2>
                  <p
                    class="text-left"
                    style="padding-bottom: 1px; padding-left: 10px"
                  >
                    Maximum allowable file size is 500 MB.
                  </p>
                  <div>
                    <UploadScopingFieldViewDocument
                      v-if="!isUpdate"
                      @uploadFilesCall="uploadFiles"
                      :isDynamicDocumentType="true"
                      :showPreviewRailroadColumn="true"
                      :isRailroadDropdownStatic="true"
                      :staticRailroadDropdownList="railroadList"
                      :staticRailroadDropdownValue="railroadValue"
                      :checkMaximumFilesUploaded="true"
                      :checkMaxFileSize="true"
                      :previewDocTypeUrl="'GET_RAILROAD_WORK_SUMMARY_DOCTYPE_LIST'"
                      :mpmsId="id"
                      :showPreviewDOTColumn="true"
                      @valueChangedEvent="valueChangedEvent($event)"
                      :isRailroadWorkSummary="true"
                      :isRailroadWorkSummaryDOTList="dotNumbersList"
                    ></UploadScopingFieldViewDocument>
                    <UploadScopingFieldViewDocument
                      v-if="isUpdate"
                      @uploadFilesCall="uploadFiles"
                      :isDynamicDocumentType="false"
                      :staticDocumentTypeList="[updateEvent.documentType]"
                      :staticDocumentTypeValue="updateEvent.documentType"
                      :isRailroadDropdownStatic="true"
                      :staticRailroadDropdownList="[updateEvent.railRoadName]"
                      :staticRailroadDropdownValue="updateEvent.railRoadName"
                      :checkMaximumFilesUploaded="true"
                      :checkMaxFileSize="true"
                      :previewDocTypeUrl="'GET_RAILROAD_WORK_SUMMARY_DOCTYPE_LIST'"
                      :mpmsId="id"
                      @valueChangedEvent="valueChangedEvent($event)"
                      :maximumFilesUploadRange="1"
                      :showDocTypeText="true"
                      :showRailroadTypeText="true"
                      :staticDOTDropdownList="[updateEvent?.dotNumber]"
                      :staticDOTDropdownValue="updateEvent?.dotNumber"
                      :isRailroadWorkSummary="true"
                      :isRailroadWorkSummaryDOTList="dotNumbersList"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-grid>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <div class="action">
            <PrimaryButton
              :disabled="checkFormInValid"
              @buttonClicked="closeDialog"
              :buttonText="'Close'"
            ></PrimaryButton>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
  <LoadingOverlay :isLoading="loading"> </LoadingOverlay>
</template>
  <script>
import RailroadWorkSummaryDocumentDialog from "@/modules/manageproject/scripts/RailroadWorkSummaryDocumentDialog.js";
export default RailroadWorkSummaryDocumentDialog;
</script>
  <style>
@import "@/modules/projectinformation/assets/projectinfostyle.scss";
@import "@/assets/css/style.scss";
</style>
  