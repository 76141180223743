import useDateField from "@/composables/dateField.js";import useIconsFunctions from "@/composables/projectIdentifiersIcons";
import DialogComponent from "@/modules/gcmsmain/admin/submenu/components/SignatureUpdateDialog.vue";
import FeatureTable from "@/modules/shared/components/FeatureTable.vue";
import PrimaryButton from "@/modules/shared/components/PrimaryButton.vue";
import axios from "axios";
import { onMounted, ref } from "vue";
import { CONFIG_ENDPOINT } from "../../../../../constants/Endpoints";
import { RBAC_CONTROLLER } from "../../../../../constants/rbacconstants";
import { userRoleBasedAccessStore } from "../../../../../stores/userRoleBasedAccess";

export default {
  name: "MaintainSignatureTemplate",
  components: { FeatureTable, DialogComponent, PrimaryButton },
  setup() {
    const { getIdentifierIcon } = useIconsFunctions();
    const lookupData = ref(null);
    const signatureDataList = ref([]);
    const loading = ref(true);
    const addOrEdit = ref("");
    const dialogData = ref(null);
    const maintainSignatureDialog = ref(null);
    let noDataAvailableText = ref(" ");
    let headers = ref([
      {
        title: "Signature Type",
        sortType: "text",
        key: "signatureType",
      },
      {
        title: "District",
        sortType: "text",
        key: "district",
      },
      {
        title: "First Name",
        sortType: "text",
        key: "firstName",
      },
      {
        title: "MI",
        sortType: "text",
        key: "middleInitial",
      },
      {
        title: "Last Name",
        sortType: "text",
        key: "lastName",
      },
      {
        title: "Title",
        sortType: "text",
        key: "title",
      },
      {
        title: "Display Text",
        sortType: "text",
        key: "displayTitle",
      },
      {
        title: "Action",
        key: "action",
        sortable: false,
        columnType: "action",
        isEdit: false,
        width: "10%",
      },
    ]);
    const paginationData = ref({
      page: 1,
      items: 15,
      total: 0,
    });
    const sortData = ref({
      key: "signatureTemplateId",
      order: "asc",
    });
    const textFilter = ref([]);
    const multipleFilter = ref([]);
    const { getFormattedDateStringNoTime } = useDateField();
    const initialLoad = ref(false);
    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };
    const allSignatureList = ref([]);

    const isSignatureTemplateUpdateAccess = ref({ read: false, update: false });
    const userRoleBasedAccessService = userRoleBasedAccessStore();

    onMounted(async () => {
      getSignatureTemplateUpdateAccess();
      await fetchData();
    });

    const getSignatureTemplateUpdateAccess = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.TEMPLATE_SIGNATURE_PAGE_READ_ACCESS
      );
      let updateResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.TEMPLATE_SIGNATURE_PAGE_CRTUPD_ACCESS
      );
      isSignatureTemplateUpdateAccess.value.read = readResponse;
      isSignatureTemplateUpdateAccess.value.update = updateResponse;
      headers.value[headers.value.length - 1].isEdit =
        isSignatureTemplateUpdateAccess.value.update;
    };

    async function fetchData() {
      let Filter = {
        signatureType: null,
        district: null,
        firstName: null,
        lastName: null,
        title: null,
      };
      textFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key) && filter.value) {
          Filter[filter.key] = filter.value;
        }
      });
      multipleFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key) && filter.value?.length) {
          Filter[filter.key] = filter.value;
        }
      });
      let filterText = "";
      if (sortData.value) {
        filterText =
          filterText +
          "?sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }
      loading.value = true;
      axios
        .post(
          CONFIG_ENDPOINT.GET_SIGNATURE_TEMPLATE_ENDPOINT + filterText,
          Filter
        )
        .then((response) => {
          setMaintainSignatureTemplate(response);
        })
        .catch((err) => {
          loading.value = false;
          signatureDataList.value = [];
          paginationData.value = {
            page: 1,
            items: paginationData.value.items,
            total: 0,
          };
          noDataAvailableText.value = "No Data Available";
        });
    }
    const setMaintainSignatureTemplate = (response) => {
      if (!initialLoad.value) {
        initialLoad.value = true;
        allSignatureList.value = response.data.templateSignaturesSummaryDTO;
      }
      loading.value = false;
      signatureDataList.value = response.data.templateSignaturesSummaryDTO;
      signatureDataList.value.forEach((item) => {
        let icons = "";
        item?.projectIdentifier?.forEach((icon) => {
          icons = icons + getIdentifierIcon(icon);
        });
        item.projectIdentifierIcon = icons;
        item.letDate = getDate(item.letDate);
      });
      lookupData.value = response.data.templateSignaturesLookUpDTO;

      headers.value.forEach((header, index) => {
        headers.value[index] = {
          ...header,
          sortable: !checkFieldDisabled(header.key, header?.filterType),
        };
      });
      noDataAvailableText.value = "No Data Available";
    };
    const checkFieldDisabled = (key, filterType) => {
      if (key === "projectIdentifier" || key === "action") {
        return true;
      }
      if (filterType != "auto-complete" || filterType != "multi-select") {
        return false;
      }
      return !lookupData.value[key]?.length;
    };
    const updatePaginationPerPage = (event) => {
      paginationData.value.page = 1;
      paginationData.value.items = event;
      fetchData();
    };
    const pageChange = (event) => {
      paginationData.value.page = event;
      fetchData();
    };
    const sortUpdate = (event) => {
      if (event.length) {
        sortData.value = {
          key: event[0].key,
          order: event[0].order,
        };
      } else {
        sortData.value = null;
      }
      fetchData();
    };
    const clearAllFilter = (event) => {
      textFilter.value = [];
      multipleFilter.value = [];
      fetchData();
    };
    const editButtonClicked = (event) => {
      addOrEdit.value = "edit";
      let clone = {};
      clone = { ...event.item, ...clone };
      dialogData.value = clone;
      openDialog("Edit");
    };
    const addButtonClicked = () => {
      addOrEdit.value = "add";
      dialogData.value = {
        signatureType: "",
        district: "",
        firstName: "",
        lastName: "",
        title: "",
        displayTitle: "",
        middleInitial: "",
      };
      openDialog("Add");
    };
    const openDialog = (dialogType) => {
      maintainSignatureDialog.value
        .open(
          dialogType + " Signature Template",
          dialogData.value,
          dialogType,
          allSignatureList.value
        )
        .then((resolve) => {
          fetchData();
        });
    };
    const textFilterChange = (event) => {
      let index = textFilter?.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value) {
          textFilter.value[index] = event;
        } else {
          textFilter.value.splice(index, 1);
        }
      } else {
        textFilter.value.push(event);
      }
    };
    const multipleFiltersChange = (event) => {
      let index = multipleFilter.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value?.length) {
          multipleFilter.value[index] = event;
        } else {
          multipleFilter.value.splice(index, 1);
        }
      } else {
        multipleFilter.value.push(event);
      }
    };
    return {
      signatureDataList,
      loading,
      headers,
      updatePaginationPerPage,
      pageChange,
      sortUpdate,
      paginationData,
      textFilterChange,
      clearAllFilter,
      editButtonClicked,
      multipleFiltersChange,
      multipleFilter,
      lookupData,
      addOrEdit,
      addButtonClicked,
      maintainSignatureDialog,
      isSignatureTemplateUpdateAccess,
      noDataAvailableText,
    };
  },
};
