import { vfs } from "@/assets/vfs_fonts.js";import useDateField from "@/composables/dateField.js";
import useSharedFunctions from "@/composables/shared";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import FeatureTable from "@/modules/shared/components/FeatureTable.vue";
import VueTable from "@/modules/shared/components/VueTable.vue";

import router from "@/router";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import pdfMake from "pdfmake/build/pdfmake";
import { onMounted, ref } from "vue";

export default {
  name: "CompletedCrossings",
  components: { FeatureTable, VueTable },
  setup() {
    const loading = ref(true);
    const completedCrossings = ref([]);
    const lookupData = ref(null);
    let noDataAvailableText = ref(" ");
    const headers = ref([
      {
        title: "DOT#",
        filter: null,
        filterType: "single-auto-complete",
        sortType: "text",
        key: "dotNumber",
        isLink: true,
      },
      {
        title: "SR",
        filter: [],
        filterType: "multi-select",
        filterSort: "number",
        sortType: "text",
        key: "sr",
      },
      {
        title: "Segment",
        filter: [],
        filterType: "multi-select",
        filterWith: "includes",
        filterSort: "number",
        sortType: "number",
        key: "segment",
      },
      {
        title: "District",
        filter: [],
        filterType: "multi-select",
        filterSort: "number",
        sortType: "text",
        key: "district",
      },
      {
        title: "County",
        filter: [],
        filterType: "auto-complete",
        filterSort: "text",
        sortType: "text",
        key: "county",
      },
      {
        title: "City",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        key: "city",
      },

      {
        title: "Crossing Type",
        filter: ["Public"],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        key: "crossingType",
      },
      {
        title: "Crossing Status",
        filter: ["Open"],
        filterType: "multi-select",
        filterSort: "date",
        filterWith: "yearMonth",
        initialSort: true,
        sortOrder: "asc",
        sortType: "date",
        key: "crossingStatus",
      },
      {
        title: "PUC Status",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        key: "pucStatus",
      },
      {
        title: "Crossing Inspection Completed Date",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        key: "inspectionDate",
      },
      {
        title: "GC Inspection Worksheet",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        key: "gcInspectionWorksheet",
        isLink: true,
        isDownload: true,
      },
    ]);
    const paginationData = ref({
      page: 1,
      items: 20,
      total: 0,
    });
    const sortData = ref({
      key: "inspectionDate",
      order: "desc",
    });

    const textFilter = ref([]);
    const multipleFilter = ref([
      { key: "crossingStatus", value: ["Open"] },
      { key: "crossingType", value: ["Public"] },
    ]);
    const { getFormattedDateStringNoTime } = useDateField();
    const currentFilters = ref(null);
    const { ExportToCSV } = useSharedFunctions();
    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };

    const isUserCompletedCrossingsAccess = ref({
      read: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const completedCrossingsCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.DISTRICT_GRADE_CROSSING_INSPECTIONS_READ_ACCESS
      );

      isUserCompletedCrossingsAccess.value.read = readResponse || false;
      loading.value = false;
      populateEmptyTable();
    };

    onMounted(async () => {
      await completedCrossingsCheck();
      console.log("DOES ACCESS?");
      console.log(isUserCompletedCrossingsAccess.value.read);
      if (isUserCompletedCrossingsAccess.value.read) {
        await fetchData();
      }
    });

    const populateEmptyTable = () => {
      completedCrossings.value = [];
      loading.value = false;
      paginationData.value = {
        page: 1,
        items: paginationData.value.items,
        total: 0,
      };
    };

    async function fetchData() {
      let Filter = {
        dotNumber: null,
        sr: null,
        segment: null,
        district: null,
        county: null,
        city: null,
        crossingType: null,
        crossingStatus: null,
        pucStatus: null,
        inspectionDate: null,
      };
      let filterText =
        "?page=" +
        (paginationData.value.page - 1) +
        "&size=" +
        paginationData.value.items;
      textFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key) && filter.value) {
          Filter[filter.key] = filter.value;
        }
      });
      multipleFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key) && filter.value?.length) {
          Filter[filter.key] = filter.value;
        }
      });
      if (sortData.value) {
        filterText =
          filterText +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }
      loading.value = true;
      axios
        .post(CONFIG_ENDPOINT.COMPLETED_CROSSINGS + filterText, Filter)
        .then((response) => {
          setCompletedCrossingsDetails(response);
          currentFilters.value = Filter;
          fetchLookupData(filterText, Filter);
        })
        .catch((err) => {
          completedCrossings.value = [];
          loading.value = false;
          paginationData.value = {
            page: 1,
            items: paginationData.value.items,
            total: 0,
          };
          noDataAvailableText.value = "No Data Available";
        });
    }
    const setCompletedCrossingsDetails = (response) => {
      completedCrossings.value = response.data.data;
      paginationData.value.total = response.data.totalElements;
      loading.value = false;
      completedCrossings.value.forEach((item) => {
        item.inspectionDate = getDate(item.inspectionDate);
        item.gcInspectionWorksheet = "Worksheet";
        headers.value.forEach((header) => {
          if (item[header.key] === null) {
            item[header.key] = "";
          }
        });
      });
      noDataAvailableText.value = "No Data Available";
    };
    const exportButtonClicked = () => {
      let filterText = "?page=" + 0 + "&size=" + paginationData.value.total;
      if (sortData.value) {
        filterText =
          filterText +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }
      axios
        .post(
          CONFIG_ENDPOINT.COMPLETED_CROSSINGS + filterText,
          currentFilters.value
        )
        .then((response) => {
          setExportCompletedCrossing(response);
        })
        .catch((err) => {});
    };
    const setExportCompletedCrossing = (response) => {
      let exportDataList = response.data.data;
      exportDataList.forEach((item) => {
        item.inspectionDate = getDate(item.inspectionDate);
        item.gcInspectionWorksheet = "Worksheet";
        headers.value.forEach((header) => {
          if (item[header.key] === null) {
            item[header.key] = "";
          }
        });
      });
      ExportToCSV(
        headers.value,
        exportDataList,
        "District Grade Crossing Inspections"
      );
    };
    const checkFieldDisabled = (key, filterType) => {
      if (key === "projectIdentifier" || key === "gcInspectionWorksheet") {
        return true;
      }
      if (filterType != "auto-complete" || filterType != "multi-select") {
        return false;
      }
      return !lookupData.value[key]?.length;
    };

    async function fetchLookupData(filterText, Filter) {
      axios
        .post(CONFIG_ENDPOINT.COMPLETED_CROSSINGS_LOOKUP + filterText, Filter)
        .then((response) => {
          lookupData.value = response.data;
          headers.value.forEach((header, index) => {
            headers.value[index] = {
              ...header,
              sortable: !checkFieldDisabled(header.key, header?.filterType),
            };
          });
        })
        .catch((err) => {
          lookupData.value = null;
          headers.value.forEach((header, index) => {
            headers.value[index] = {
              ...header,
              sortable: !checkFieldDisabled(header.key, header?.filterType),
            };
          });
        });
    }

    const updatePaginationPerPage = (event) => {
      paginationData.value.page = 1;
      paginationData.value.items = event;
      fetchData();
    };
    const pageChange = (event) => {
      paginationData.value.page = event;
      fetchData();
    };

    const clearAllFilter = (event) => {
      textFilter.value = [];
      multipleFilter.value = [];
      fetchData();
    };
    const textFilterChange = (event) => {
      let index = textFilter.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value) {
          textFilter.value[index] = event;
        } else {
          textFilter.value.splice(index, 1);
        }
      } else {
        textFilter.value.push(event);
      }
    };
    const multipleFiltersChange = (event) => {
      let index = multipleFilter.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value?.length) {
          multipleFilter.value[index] = event;
        } else {
          multipleFilter.value.splice(index, 1);
        }
      } else {
        multipleFilter.value.push(event);
      }
    };
    const crossingPdfData = ref(null);

    const linkClicked = (event) => {
      if (event.key === "gcInspectionWorksheet") {
        axios
          .get(
            "/gcms-gradecrossing-service/api/inspections/worksheet/" +
              event.value.dotNumber
          )
          .then((res) => {
            crossingPdfData.value = res.data;
            GeneratePDF();
          })
          .catch(() => {});
      } else {
        router.push({ path: "DOT/" + event.value });
      }
    };
    const sortUpdate = (event) => {
      if (event.length) {
        sortData.value = {
          key: event[0].key,
          order: event[0].order,
        };
      } else {
        sortData.value = null;
      }
      fetchData();
    };
    const GeneratePDF = () => {
      let pdfData = [
        {
          text: "GRADE CROSSING INSPECTION WORKSHEET",
          fontSize: 15,
          decoration: "underline",
          alignment: "center",
          bold: true,
          margin: [0, 0, 0, 10],
        },
        {
          margin: [0, 0, 0, 10],
          columns: [
            { width: "15%", text: "" },
            {
              width: "20%",
              columns: [
                {
                  width: "50%",
                  text: crossingPdfData.value.data[0].label,
                  alignment: "right",
                  bold: true,
                  fontSize: "9.5",
                },
                {
                  width: "50%",
                  text: crossingPdfData.value.data[0].value,
                  alignment: "left",
                  fontSize: "9.5",
                },
              ],
            },
            {
              width: "25%",
              columns: [
                {
                  width: "50%",
                  text: crossingPdfData.value.data[1].label,
                  alignment: "right",
                  bold: true,
                  fontSize: "9.5",
                },
                {
                  width: "50%",
                  text: crossingPdfData.value.data[1].value,
                  alignment: "left",
                  fontSize: "9.5",
                },
              ],
            },
            {
              columns: [
                {
                  width: "15%",
                  text: crossingPdfData.value.data[2].label,
                  alignment: "right",
                  bold: true,
                  fontSize: "9.5",
                },
                {
                  width: "85%",
                  text: crossingPdfData.value.data[2].value,
                  alignment: "left",
                  fontSize: "9.5",
                },
              ],
            },
          ],
        },
        {
          margin: [0, 0, 0, 10],
          columns: [
            {
              width: "10%",
              columns: [
                {
                  text: crossingPdfData.value.data[3].label,
                  width: "50%",
                  alignment: "right",
                  bold: true,
                  fontSize: "9.5",
                },
                {
                  text: crossingPdfData.value.data[3].value,
                  width: "50%",
                  alignment: "left",
                  fontSize: "9.5",
                },
              ],
            },
            {
              width: "20%",
              columns: [
                {
                  text: crossingPdfData.value.data[4].label,
                  width: "50%",
                  alignment: "right",
                  bold: true,
                  fontSize: "9.5",
                },
                {
                  text: crossingPdfData.value.data[4].value,
                  width: "50%",
                  alignment: "left",
                  fontSize: "9.5",
                },
              ],
            },
            {
              width: "40%",
              columns: [
                {
                  text: crossingPdfData.value.data[5].label,
                  width: "50%",
                  alignment: "right",
                  bold: true,
                  fontSize: "9.5",
                },
                {
                  text: crossingPdfData.value.data[5].value,
                  width: "50%",
                  alignment: "left",
                  fontSize: "9.5",
                },
              ],
            },
            {
              width: "30%",
              columns: [
                {
                  text: crossingPdfData.value.data[6].label,
                  width: "60%",
                  alignment: "right",
                  bold: true,
                  fontSize: "9.5",
                },
                {
                  text: "____________",
                  width: "40%",
                  alignment: "left",
                  fontSize: "9.5",
                },
              ],
            },
          ],
        },
      ];
      crossingPdfData.value.data.forEach((comment, index) => {
        if (index > 6 && index < 43) {
          pdfData.push({
            margin: [0, 0, 0, 5],
            columns: [
              {
                columns: [
                  {
                    width: "10%",
                    text: Number(comment.label.split(". ")[0])
                      ? Number(comment.label.split(". ")[0]) + "."
                      : "",
                    fontSize: "9.5",
                    bold: true,
                  },
                  {
                    text: [
                      {
                        text: Number(comment.label.split(". ")[0])
                          ? comment.label.split(". ")[1]
                          : comment.label,
                        fontSize: "9.5",
                        bold: true,
                      },
                      { text: comment.value, fontSize: "9.5" },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    width: "10%",
                    text: Number(
                      crossingPdfData.value.data[36 + index]?.label.split(
                        ". "
                      )[0]
                    )
                      ? Number(
                          crossingPdfData.value.data[36 + index]?.label.split(
                            ". "
                          )[0]
                        ) + "."
                      : "",
                    fontSize: "9.5",
                    bold: true,
                  },
                  {
                    text: [
                      {
                        text: Number(
                          crossingPdfData.value.data[36 + index]?.label.split(
                            ". "
                          )[0]
                        )
                          ? crossingPdfData.value.data[36 + index]?.label.split(
                              ". "
                            )[1]
                          : crossingPdfData.value.data[36 + index]?.label,
                        fontSize: "9.5",
                        bold: true,
                      },
                      {
                        text: crossingPdfData.value.data[36 + index]?.value,
                        fontSize: "9.5",
                      },
                    ],
                  },
                ],
              },
            ],
          });
        }
      });
      pdfMake.vfs = vfs;

      pdfMake.fonts = {
        Roboto: {
          normal: "Roboto-Regular.ttf",
          bold: "Roboto-Medium.ttf",
          italics: "Roboto-Italic.ttf",
          bolditalics: "Roboto-MediumItalic.ttf",
        },
        FontAwesomeSolid: {
          normal: "fa-solid-900.ttf",
          bold: "fa-solid-900.ttf",
          italics: "fa-solid-900.ttf",
          bolditalics: "fa-solid-900.ttf",
        },
        CustomFontAwesome: {
          normal: "fontawesome-regular-checkboxes.ttf",
          bold: "fontawesome-regular-checkboxes.ttf",
          italics: "fontawesome-regular-checkboxes.ttf",
          bolditalics: "fontawesome-regular-checkboxes.ttf",
        },
      };
      let content = {
        content: pdfData,
        styles: {
          header: {
            fontSize: 18,
            bold: true,
          },
          bigger: {
            fontSize: 15,
            italics: true,
          },
          pdfHeader: {
            fontSize: 15,
            decoration: "underline",
            alignment: "center",
            bold: true,
          },
        },
        defaultStyle: {
          columnGap: 5,
        },
      };
      pdfMake.createPdf(content).open();
    };
    return {
      completedCrossings,
      headers,
      updatePaginationPerPage,
      pageChange,
      paginationData,
      textFilterChange,
      clearAllFilter,
      multipleFiltersChange,
      lookupData,
      GeneratePDF,
      linkClicked,
      fetchLookupData,
      sortUpdate,
      loading,
      multipleFilter,
      exportButtonClicked,
      isUserCompletedCrossingsAccess,
      noDataAvailableText,
    };
  },
};
