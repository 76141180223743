<template>
  <div class="mprs-header-div">
    <div v-if="isProjectPriorityAccess && !create">
      <PriorityProject
        :id="id"
        @refreshData="$emit('refreshMpmsData')"
      ></PriorityProject>
    </div>
    <div class="page-title">
      <h1>Project Information</h1>
    </div>
    <div class="env-infp">
      <div class="env-header">
        <h2 class="locationHeaderText">MPMS & Environmental Information</h2>
      </div>
      <div class="env-content mpmsEnvForm" v-if="mpmsEnvironment">
        <v-row class="center" v-if="create">
          <v-col cols="6">
            <label class="mpms-env-label"
              ><v-row
                ><v-col cols="5"
                  ><b class="d-flex justify-content-end text-right"
                    >MPMS #:
                  </b></v-col
                ><v-col cols="7">{{ mpmsEnvironment.mpmsNumber }}</v-col></v-row
              >
            </label>
          </v-col>
          <v-col cols="6">
            <label class="mpms-env-label"
              ><v-row
                ><v-col cols="3"
                  ><b class="d-flex justify-content-end text-right"
                    >SR-SEC:
                  </b></v-col
                ><v-col cols="9">
                  {{ mpmsEnvironment.stateRoute }}-{{
                    mpmsEnvironment.srSection
                  }}</v-col
                ></v-row
              ></label
            >
          </v-col>
          <v-col cols="6">
            <label class="mpms-env-label"
              ><v-row
                ><v-col cols="5"
                  ><b class="d-flex justify-content-end text-right"
                    >County:
                  </b></v-col
                ><v-col cols="7"> {{ mpmsEnvironment.county }}</v-col></v-row
              ></label
            >
          </v-col>
          <v-col cols="6">
            <label class="mpms-env-label"
              ><v-row
                ><v-col cols="3"
                  ><b class="d-flex justify-content-end text-right"
                    >Municipality:
                  </b></v-col
                ><v-col cols="9">
                  {{ mpmsEnvironment.municipality }}</v-col
                ></v-row
              ></label
            >
          </v-col>
        </v-row>

        <v-row class="center">
          <v-col cols="6">
            <label class="mpms-env-label"
              ><v-row
                ><v-col cols="5"
                  ><b class="d-flex justify-content-end text-right"
                    >Project Improvement Description:
                  </b></v-col
                ><v-col cols="7">{{
                  mpmsEnvironment?.projectImprovementDescription
                }}</v-col></v-row
              >
            </label>
          </v-col>
          <v-col cols="6">
            <label class="mpms-env-label"
              ><v-row
                ><v-col cols="3"
                  ><b class="d-flex justify-content-end text-right"
                    >Project Class:
                  </b></v-col
                ><v-col cols="9">
                  {{ mpmsEnvironment?.projectClass }}</v-col
                ></v-row
              ></label
            >
          </v-col>
        </v-row>
        <v-row justify="between" align="center">
          <v-col cols="6">
            <label class="mpms-env-label"
              ><v-row
                ><v-col cols="5"
                  ><b class="d-flex justify-content-end text-right"
                    >Project Location Description:
                  </b></v-col
                ><v-col cols="7">
                  {{ mpmsEnvironment?.projectLocationDescription }}</v-col
                ></v-row
              ></label
            >
          </v-col>
          <v-col cols="6">
            <v-row cols="12" justify="start" align="center">
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="6"
                      ><b class="d-flex justify-content-end text-right"
                        >PM Email:
                      </b></v-col
                    ><v-col cols="6">
                      {{ mpmsEnvironment?.projectManagerEmail }}</v-col
                    ></v-row
                  ></label
                >
              </v-col>
              <v-col cols="6">
                <label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="6"
                      ><b class="d-flex justify-content-end text-right"
                        >PM Phone:
                      </b></v-col
                    ><v-col cols="6">
                      {{ mpmsEnvironment?.projectManagerPhoneNumber }}</v-col
                    ></v-row
                  ></label
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="pt-0" justify="start" align="center">
          <v-col cols="6">
            <label class="mpms-env-label"
              ><v-row
                ><v-col cols="5"
                  ><b class="d-flex justify-content-end text-right"
                    >Federal Project Status:
                  </b></v-col
                ><v-col cols="7">
                  {{ mpmsEnvironment?.federalProjectStatus }}</v-col
                ></v-row
              ></label
            >
          </v-col>
          <v-col cols="6"></v-col>
        </v-row>
        <v-row class="pt-0" justify="start" align="center">
          <v-col cols="6">
            <label class="mpms-env-label"
              ><v-row
                ><v-col cols="5"
                  ><b class="d-flex justify-content-end text-right"
                    >Project Environment Documents:
                  </b></v-col
                ><v-col cols="7">
                  <a
                    target="_blank"
                    :href="mpmsEnvironment?.projectEnvironmentDocuments"
                    >Categorical Exclusion Document</a
                  ></v-col
                ></v-row
              ></label
            >
          </v-col>

          <v-col cols="6">
            <v-row justify="start" align="center">
              <v-col cols="12"
                ><label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="3"
                      ><b
                        class="d-flex justify-content-end text-right"
                        v-if="!create"
                        >PUC Docket#:
                      </b></v-col
                    >
                    <v-col
                      cols="9"
                      justify="start"
                      align="center"
                      v-if="!create"
                    >
                      <v-text-field
                        variant="outlined"
                        light
                        type="text"
                        class="permitAppTextField p-0 puc-docket-input"
                        v-model="pucDocketNumber"
                        tabindex="1"
                        id="txt_id"
                        maxlength="14"
                        minlength="8"
                        :validate-on="'input'"
                        :rules="getPUCDocketRule ? [validPUCDocketNumber] : []"
                        @update:modelValue="
                          docketNumberChange($event);
                          debounce(() => {
                            setPUCDocketRule($event);
                          });
                        "
                        @keypress="keydownAllowAlphaNumericOnly($event)"
                      ></v-text-field> </v-col></v-row></label
              ></v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="start" align="center">
          <v-col cols="6">
            <label class="mpms-env-label"
              ><v-row
                ><v-col cols="5"
                  ><b class="d-flex justify-content-end text-right"
                    >Project Manager:
                  </b></v-col
                ><v-col cols="7">
                  {{ mpmsEnvironment?.projectManager }}</v-col
                ></v-row
              ></label
            >
          </v-col>
          <v-col cols="6" class="mt-5">
            <v-row cols="12" justify="start" align="center">
              <v-col cols="12"
                ><label class="mpms-env-label"
                  ><v-row
                    ><v-col cols="3"
                      ><b
                        class="d-flex justify-content-end text-right"
                        :class="{ required: pucDocketNumber }"
                        v-if="!create"
                        ><span>PUC Docket# Date:</span>
                      </b></v-col
                    >
                    <v-col
                      cols="3"
                      justify="start"
                      align="center"
                      v-if="!create"
                    >
                      <DatePicker
                        tabindex="2"
                        id="txt_id"
                        :value="pucDocketNumberDate"
                        :invalidMsg="'PUC Docket# Date'"
                        :isPucDocket="true"
                        :disabled="!pucDocketNumber"
                        @input="dateChange"
                        @dateValidation="checkDateValidation($event)"
                        :rules="
                          pucDocketNumber
                            ? [
                                'requiredRule',
                                'validDateFutureDate_Inspections',
                              ]
                            : []
                        "
                      /> </v-col></v-row></label
              ></v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <div class="env-content text-center" v-if="!mpmsEnvironment">
        <v-card><p class="p-20">No Data Available</p></v-card>
      </div>
    </div>

    <div v-if="!create">
      <div class="w-100 d-flex justify-content-center flex-column">
        <v-row cols="12">
          <v-col>
            <div class="env-header mt-20 mb-30">
              <h2 class="locationHeaderText"><b>Parent & Child Projects</b></h2>
            </div>
          </v-col>
        </v-row>

        <v-row cols="12">
          <v-col>
            <div>
              <v-expansion-panels v-model="panel">
                <v-expansion-panel>
                  <v-expansion-panel-title
                    disable-icon-rotate
                    class="parent-child-expansion"
                  >
                    <div class="expansion-header">
                      <section class="icon-section f-size16">
                        <v-icon
                          @click="isExpanded = !isExpanded"
                          class="icons"
                          v-if="!isExpanded"
                          icon="mdi-plus"
                        ></v-icon
                        ><v-icon
                          @click="isExpanded = !isExpanded"
                          class="icons"
                          v-if="isExpanded"
                          icon="mdi-minus"
                        ></v-icon>
                      </section>
                      <section
                        class="mpms-section f-size14"
                        :class="{
                          link:
                            parentChildData?.gcmsProject?.gcmsProjectFlag ===
                            'Y',
                        }"
                        @click.self="
                          parentChildData?.gcmsProject?.gcmsProjectFlag === 'Y'
                            ? navigateToMPMSDetails(parentChildData?.mpmsNumber)
                            : ''
                        "
                      >
                        MPMS#:{{ parentChildData?.mpmsNumber }}
                      </section>
                      <section class="title-section f-size14">
                        {{ parentChildData?.projectTitle }}
                      </section>
                      <section class="srSec-section f-size14">
                        {{ parentChildData?.stateRoute }}-{{
                          parentChildData?.srSection
                        }}
                      </section>
                      <section class="county-section f-size14">
                        County: {{ parentChildData?.county }}
                      </section>
                      <section class="municipality-section f-size14">
                        Municipality: {{ parentChildData?.municipality }}
                      </section>
                      <section class="status-section f-size14">
                        Status: {{ parentChildData?.projectStatus }}
                      </section>
                      <section class="w-10">
                        {{
                          parentChildData?.gcmsProject?.gcmsProjectFlag === "Y"
                            ? " "
                            : "Not created in GCMS"
                        }}
                      </section>
                    </div>
                  </v-expansion-panel-title>
                  <v-expansion-panel-text>
                    <div
                      class="expanded"
                      v-if="parentChildData?.children?.length"
                    >
                      <div
                        class="expansion-header"
                        v-for="child in parentChildData?.children"
                        :key="child"
                      >
                        <section class="icon-section f-size14"></section>
                        <section class="icon-section f-size14">
                          <v-icon class="icons" icon="mdi-minus"></v-icon>
                        </section>
                        <section class="mpms-sub-section f-size14">
                          <a
                            :class="{
                              link: child?.gcmsProject?.gcmsProjectFlag === 'Y',
                            }"
                            @click="
                              child?.gcmsProject?.gcmsProjectFlag === 'Y'
                                ? navigateToMPMSDetails(child?.mpmsNumber)
                                : ''
                            "
                            >MPMS#:{{ child?.mpmsNumber }}</a
                          >
                        </section>
                        <section class="title-section f-size14">
                          {{ child?.projectTitle }}
                        </section>
                        <section class="srSec-section f-size14">
                          {{ child?.stateRoute }}-{{ child?.srSection }}
                        </section>
                        <section class="county-section f-size14">
                          County: {{ child?.county }}
                        </section>
                        <section class="municipality-section f-size14">
                          Municipality: {{ child?.municipality }}
                        </section>
                        <section class="status-section f-size14">
                          Status: {{ child?.projectStatus }}
                        </section>

                        <section class="w-10">
                          {{
                            child?.gcmsProject?.gcmsProjectFlag === "Y"
                              ? " "
                              : "Not created in GCMS"
                          }}
                        </section>
                      </div>
                    </div>
                    <div
                      class="expanded"
                      v-if="!parentChildData?.children?.length"
                    >
                      <p class="text-center">
                        No Parent/Child Projects found for this Project.
                      </p>
                    </div>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </v-col>
        </v-row>

        <br />
        <v-row cols="12" class="mt-20">
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <AddComments
              :id="id"
              :commentMaxLength="commentMaxLength"
              :projectComments="projectComments"
              :commentType="'Project Comments'"
              :sectionTitle="'Project Comments:'"
              @addComment="addComment($event)"
              @commentUpdate="commentUpdateToTab($event)"
              :saveClicked="saveClicked"
              :cancelClicked="cancelClicked"
              :isUserCommentAccess="isUserCommentAccess"
            ></AddComments>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>

        <v-row
          cols="12"
          v-if="isUserCommentAccess.read || isUserCommentAccess.update"
        >
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <PreviousComments
              :id="id"
              v-if="projectComments?.length"
              :saveClicked="saveClicked"
              :projectComments="projectComments"
              :exportType="'comments'"
              :sectionTitle="'Previous Project Comments:'"
            ></PreviousComments>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>

        <v-row cols="12">
          <v-col cols="12">
            <div class="env-header mt-20">
              <h2 class="locationHeaderText">
                <b>Scope Railroad Involvement</b>
              </h2>
            </div>
          </v-col>
        </v-row>

        <v-row cols="12">
          <v-col cols="12">
            <div class="view-upload-section">
              <span
                class="color-blue underline cursor-pointer"
                @click="openScopingModal()"
                >View/Upload Scope Field View Document</span
              >
            </div>
          </v-col>
        </v-row>
        <div
          class="mt-20"
          v-if="mpmsEnvironment?.gcmsProject?.safetyProject === 'Y'"
        >
          <div class="w-100 d-flex align-items-center">
            <div class="w-10">
              <h4 class="text-right">
                <b>Section 130 <br />Project Type: </b>
              </h4>
            </div>
            <div class="w-40 ml-20">
              <v-select
                outlined
                type="text"
                :items="section130Lookup"
                v-model="selectedField"
                item-title="section130projecttype"
                item-value="section130projecttypeCode"
                @update:modelValue="updateSelectedFieldfor130()"
              ></v-select>
            </div>
          </div>
        </div>
        <div class="mt-20">
          <div class="w-100" v-if="mpmsEnvironment">
            <div class="w-10">
              <h4
                :class="{
                  'text-right':
                    mpmsEnvironment?.gcmsProject?.safetyProject === 'Y',
                  'text-left':
                    mpmsEnvironment?.gcmsProject?.safetyProject != 'Y',
                }"
              >
                <b>Crossing Scope: </b>
              </h4>
            </div>
            <div class="w-100">
              <v-container
                class="white-textarea comment-box"
                style="padding: 0"
              >
                <v-textarea
                  :counter="850"
                  :maxlength="850"
                  :persistent-counter="true"
                  :rules="[requiredRule]"
                  v-model="mpmsEnvironment.crossingScopeDesc"
                  variant="solo"
                  rows="8"
                  no-resize
                  @update:modelValue="updateCrossingScopeValue"
                ></v-textarea>
                <span style="display: flex; height: 40px"
                  >{{
                    850 - (mpmsEnvironment?.crossingScopeDesc?.length || 0)
                  }}
                  characters remaining.</span
                >
              </v-container>
            </div>
          </div>
          <v-row cols="12">
            <v-col cols="12">
              <AddComments
                :id="id"
                :commentMaxLength="commentMaxLength"
                :projectComments="projectScopeFieldComments"
                :saveClicked="saveClicked"
                :cancelClicked="cancelClicked"
                :commentType="'Scoping Field View Comments'"
                :sectionTitle="'Scoping Field View Comments:'"
                @addComment="addScopeFieldComment($event)"
                @commentUpdate="commentUpdateToTab($event)"
                :isUserCommentAccess="isUserCommentAccess"
              ></AddComments>
            </v-col>
          </v-row>

          <v-row
            cols="12"
            v-if="isUserCommentAccess.read || isUserCommentAccess.update"
          >
            <v-col cols="12">
              <PreviousComments
                :id="id"
                v-if="projectScopeFieldComments?.length"
                :projectComments="projectScopeFieldComments"
                :exportType="'scopeFieldComments'"
                :sectionTitle="'Previous Scoping Field View Comments:'"
              ></PreviousComments>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
  <ScopingViewDialogComponent
    ref="scopingViewDialogComponent"
  ></ScopingViewDialogComponent>
</template>
<script>
import MpmsEnvironmentInfo from "@/modules/projectinformation/scripts/MpmsEnvironmentInfo.js";
export default MpmsEnvironmentInfo;
</script>
<style>
@import "@/assets/css/style.scss";
</style>