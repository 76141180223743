<template>
  <v-dialog
    persistent
    @afterLeave="afterModalLeave"
    v-model="dialog"
    max-width="950px"
    max-height="3000px"
  >
    <div class="dialogheading district-office-form">
      <div class="w-100 d-flex justify-content-between">
        <div class="w-90 text-center f-size24">
          {{ title }}
        </div>
        <div>
          <v-icon @click="closeDialog" class="cursor-pointer" small
            >mdi-close
          </v-icon>
        </div>
      </div>
      <v-card v-if="formData">
        <v-card-text>
          <v-form v-model="isFormValid">
            <v-row class="dialogRow" cols="12">
              <v-col
                class="dialogLabeltext d-flex justify-content-end"
                cols="2"
              >
                <label>Signature Type: </label>
                <label class="asterisk" v-if="dialogType === 'Add'">*</label>
              </v-col>
              <v-col
                class="dialogLabeltext d-flex justify-content-start"
                cols="3"
              >
                <label class="normalText" v-if="dialogType === 'Edit'">{{
                  formData?.signatureType
                }}</label>
                <v-select
                  v-if="dialogType === 'Add'"
                  v-model="formData.signatureType"
                  :items="lookupData?.signatureType"
                  outlined
                  :rules="[requiredRule]"
                ></v-select>
              </v-col>
              <v-col
                class="dialogLabeltext d-flex justify-content-end"
                cols="2"
              >
                <label>District: </label>
                <label
                  class="asterisk"
                  v-if="
                    dialogType === 'Add' &&
                    formData.signatureType === 'District'
                  "
                  >*</label
                >
              </v-col>
              <v-col
                class="dialogLabeltext d-flex justify-content-start"
                cols="3"
              >
                <label class="normalText" v-if="dialogType === 'Edit'">{{
                  formData?.district
                }}</label>
                <v-select
                  v-if="
                    dialogType === 'Add' &&
                    formData.signatureType === 'District'
                  "
                  v-model="formData.district"
                  :items="lookupData?.district"
                  outlined
                  :rules="[requiredRule]"
                ></v-select>
                <v-select
                  v-if="
                    dialogType === 'Add' && formData.signatureType != 'District'
                  "
                  v-model="formData.district"
                  :items="lookupData?.district"
                  outlined
                ></v-select>
              </v-col>
            </v-row>

            <v-row class="dialogRow" cols="12">
              <v-col
                class="dialogLabeltext d-flex justify-content-end"
                cols="1"
              >
                <label>Title: </label>
              </v-col>
              <v-col
                class="dialogLabeltext d-flex justify-content-start"
                cols="4"
              >
                <v-select
                  v-model="formData.title"
                  :items="lookupData?.title"
                  outlined
                ></v-select>
              </v-col>
              <v-col
                class="dialogLabeltext d-flex justify-content-end"
                cols="2"
              >
                <label>Display Title: </label>
              </v-col>
              <v-col
                class="dialogLabeltext d-flex justify-content-start"
                cols="4"
              >
                <v-text-field
                  v-model="formData.displayTitle"
                  type="text"
                  min="0"
                  maxlength="80"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="dialogRow" cols="12">
              <v-col
                class="dialogLabeltext d-flex justify-content-end"
                cols="2"
              >
                <label>First Name: </label>
                <label class="asterisk">*</label>
              </v-col>
              <v-col
                class="dialogLabeltext d-flex justify-content-start"
                cols="3"
              >
                <v-text-field
                  v-model="formData.firstName"
                  :rules="[requiredRule]"
                  @keydown="allowAlphaOnly($event)"
                  type="text"
                  min="0"
                  maxlength="50"
                >
                </v-text-field>
              </v-col>
              <v-col
                class="dialogLabeltext d-flex justify-content-end"
                cols="2"
              >
                <label>Middle Initial: </label>
              </v-col>
              <v-col
                class="dialogLabeltext d-flex justify-content-start"
                cols="3"
              >
                <v-text-field
                  v-model="formData.middleInitial"
                  type="text"
                  min="0"
                  maxlength="50"
                  @keydown="allowAlphaOnly($event)"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="dialogRow" cols="12">
              <v-col
                class="dialogLabeltext d-flex justify-content-end"
                cols="2"
              >
                <label>Last Name: </label>
                <label class="asterisk">*</label>
              </v-col>
              <v-col
                class="dialogLabeltext d-flex justify-content-start"
                cols="3"
              >
                <v-text-field
                  v-model="formData.lastName"
                  :rules="[requiredRule]"
                  type="text"
                  min="0"
                  maxlength="50"
                  @keydown="allowAlphaOnly($event)"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <div class="signatureError" v-if="checkIfSignatureAlreadyExists">
            <div>
              <v-icon icon="mdi-alert"></v-icon>
              Template Signature already exists.
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <div class="action">
            <PrimaryButton
              @buttonClicked="saveData"
              :buttonText="'Save'"
              :disabled="
                !isFormValid || checkIfSignatureAlreadyExists || !valueChanged
              "
            ></PrimaryButton>
            <SecondaryButton
              @buttonClicked="closeDialog"
              :buttonText="'Cancel'"
              class="ml-2"
            ></SecondaryButton>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
import SignatureUpdateDialog from "@/modules/gcmsmain/admin/submenu/scripts/SignatureUpdateDialog.js";

export default SignatureUpdateDialog;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>
