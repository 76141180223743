import useDateField from "@/composables/dateField.js";
import useIconsFunctions from "@/composables/projectIdentifiersIcons";
import useSharedFunctions from "@/composables/shared";
import { CONFIG_ENDPOINT } from "@/constants/Endpoints";
import { RBAC_CONTROLLER } from "@/constants/rbacconstants";
import DialogComponent from "@/modules/dot-details/components/PucDocumentsModal.vue";
import FeatureTable from "@/modules/shared/components/FeatureTable.vue";
import sharedUserService from "@/modules/shared/service/sharedUserService";
import { userRoleBasedAccessStore } from "@/stores/userRoleBasedAccess";
import axios from "axios";
import { computed, onMounted, ref } from "vue";


export default {
  name: "GradeCrossingProjects",
  components: { FeatureTable, DialogComponent },
  props: ["id", "tab"],
  setup(props) {
    const { getIdentifierIcon } = useIconsFunctions();
    const lookupData = ref(null);
    const activeProjects = ref([]);
    const loading = ref(true);
    const dialogComponent = ref(null);

    const userData = computed({
      get: () => {
        return sharedUserService.getSharedData();
      },
    });
    let headers = ref([
      {
        title: "",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        key: "projectIdentifier",
        columnType: "icon",
        icon: "projectIdentifierIcon",
        sortable: false,
      },
      {
        title: "MPMS#",
        filter: null,
        filterType: "single-auto-complete",
        key: "mpmsNumber",
        isLink: true,
      },
      {
        title: "Project Status",
        filter: [],
        filterType: "multi-select",
        filterSort: "number",
        sortType: "text",
        key: "projectStatus",
        width: "25%",
      },
      {
        title: "Docket #",
        filter: null,
        filterType: "textbox",
        filterWith: "includes",
        sortType: "text",
        key: "pucDocketNumber",
        isLink:true,
        isDownload:true,
      },
      {
        title: "District",
        sortType: "text",
        key: "district",
      },
      {
        title: "County",
        sortType: "text",
        key: "county",
      },
      {
        title: "SR",
        filter: [],
        filterType: "multi-select",
        filterSort: "number",
        sortType: "text",
        key: "stateRoute",
      },
      {
        title: "Section",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        key: "srSection",
      },
    ]);
    const paginationData = ref({
      page: 1,
      items: 20,
      total: 0,
    });
    const sortData = ref({
      key: "",
      order: "",
    });
    const textFilter = ref([]);
    const multipleFilter = ref([]);
    const { getFormattedDateStringNoTime } = useDateField();
    const currentFilters = ref(null);
    const { ExportToCSV } = useSharedFunctions();
    const dotId = ref(props?.id);
    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };
    const isUserGradeCrossingAccess = ref({
      read: false,
    });
    const userRoleBasedAccessService = userRoleBasedAccessStore();
    const gradeCrossingAccessCheck = async () => {
      let readResponse = await userRoleBasedAccessService.checkUserAccess(
        RBAC_CONTROLLER.GRADE_CROSSING_RECORD_READ_ACCESS
      );
     
      isUserGradeCrossingAccess.value.read = readResponse || false;
    };

    onMounted(async () => {
      await gradeCrossingAccessCheck();
      if (isUserGradeCrossingAccess.value.read){
        await fetchData();
      } else {
        loading.value = false;
        activeProjects.value = [];
        paginationData.value = {
          page: 1,
          items: paginationData.value.items,
          total: 0,
      };

      }
    });
    const getLookupData = (filterText, Filter) => {
      let filterText1 =
        "?page=" +
        (paginationData.value.page - 1) +
        "&size=" +
        paginationData.value.items +
        "&userID=" +
        userData.value?.userID +
        "&isUserProject=" +
        true;
      if (sortData.value) {
        filterText1 =
          filterText1 +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }
      axios
        .post(
          CONFIG_ENDPOINT.DOT_PROJECT_LIST_LOOKUP + dotId.value + filterText1,
          Filter
        )
        .then((response) => {
          lookupData.value = response.data;
          headers.value.forEach((header, index) => {
            headers.value[index] = {
              ...header,
              sortable: !checkFieldDisabled(header.key, header?.filterType),
            };
          });
        })
        .catch((err) => {
          lookupData.value = null;
        });
    };
    async function fetchData() {
      let Filter = getFilterData();
      let filterText = getFilterText();
      try {
        loading.value = true;
        const response = await axios.post(
          CONFIG_ENDPOINT.DOT_PROJECT_LIST + dotId.value + filterText,
          Filter
        );
        handleFetchSuccess(response, Filter);
      } catch (err) {
        handleFetchError();
      }
    }
    function getFilterData() {
      let Filter = {
        projectIdentifier: null,
        mpmsNumber: null,
        projectStatus: null,
        pucDocketNumber: null,
        district: null,
        county: null,
        stateRoute: null,
        srSection: null,
      };
      textFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key) && filter.value) {
          Filter[filter.key] = filter.value;
        }
      });
      multipleFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter?.key) && filter.value) {
          Filter[filter.key] = filter.value;
        }
      });
      return Filter;
    }
    function getFilterText() {
      let filterText =
        "?page=" +
        (paginationData.value.page - 1) +
        "&size=" +
        paginationData.value.items +
        "&userID=" +
        userData.value?.userID;
      if (sortData.value) {
        filterText +=
          "&sort=" + sortData.value.key + "&direction=" + sortData.value.order;
      }
      return filterText;
    }
    function handleFetchSuccess(response, Filter) {
      loading.value = false;
      activeProjects.value = response.data.data;
      activeProjects.value.forEach((item) => {
        console.log(
          "activeProjects",
          item?.projectIdentifier,
          item?.projectIdentifier.includes("Historical")
        );
        item.hideLink = item?.projectIdentifier?.includes("Historical");
        item.projectIdentifierIcon = processProjectIdentifier(
          item?.projectIdentifier
        );
        item.projectIdentifierIcon = processProjectIdentifier(
          item?.projectIdentifier
        );
        item.letDate = getDate(item.letDate);
      });
      paginationData.value.total = response.data.totalElements;
      currentFilters.value = Filter;
      getLookupData(getFilterText(), Filter);
    }
    function handleFetchError() {
      loading.value = false;
      activeProjects.value = [];
      paginationData.value = {
        page: 1,
        items: paginationData.value.items,
        total: 0,
      };
    }
    const exportButtonClicked = () => {
      let filterText = getExportFilterText();
      axios
        .post(
          CONFIG_ENDPOINT.GCMS_MAIN_ACTIVE_PROJECTS + filterText,
          currentFilters.value
        )
        .then((response) => {
          processExportData(response);
        })
        .catch((err) => {});
    };
    function getExportFilterText() {
      let filterText = "?page=0&size=" + paginationData.value.total;
      if (sortData.value) {
        filterText +=
          "&sort=" + sortData.value.key + "&direction=" + sortData.value.order;
      }
      return filterText;
    }
    function processExportData(response) {
      loading.value = false;
      let exportDataList = response.data.data;
      exportDataList.forEach((item) => {
        item.projectIdentifierIcon = processProjectIdentifier(
          item?.projectIdentifier
        );
        item.letDate = getDate(item.letDate);
      });
      ExportToCSV(headers.value, exportDataList, "Active Projects");
    }
    function processProjectIdentifier(identifiers) {
      let icons = "";
      if (identifiers) {
        identifiers.forEach((icon) => {
          icons += getIdentifierIcon(icon);
        });
      }
      return icons;
    }
    const checkFieldDisabled = (key, filterType) => {
      if (key === "projectIdentifier") {
        return true;
      }
      if (
        filterType != "auto-complete" ||
        filterType != "multi-select" ||
        filterType != "single-auto-complete"
      ) {
        return false;
      }
      if (
        lookupData.value[key]?.length === 1 &&
        lookupData.value[key][0] === null
      ) {
        return true;
      }
      return !lookupData.value[key]?.length;
    };
    const updatePaginationPerPage = (event) => {
      paginationData.value.page = 1;
      paginationData.value.items = event;
      fetchData();
    };
    const pageChange = (event) => {
      paginationData.value.page = event;
      fetchData();
    };
    const sortUpdate = (event) => {
      if (event.length) {
        sortData.value = {
          key: event[0].key,
          order: event[0].order,
        };
      } else {
        sortData.value = null;
      }
      fetchData();
    };
    const clearAllFilter = (event) => {
      textFilter.value = [];
      multipleFilter.value = [];
      fetchData();
    };
    const textFilterChange = (event) => {
      let index = textFilter?.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value) {
          textFilter.value[index] = event;
        } else {
          textFilter.value.splice(index, 1);
        }
      } else {
        textFilter.value.push(event);
      }
      fetchData();
    };

    const multipleFiltersChange = (event) => {
      let index = multipleFilter.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value?.length) {
          multipleFilter.value[index] = event;
        } else {
          multipleFilter.value.splice(index, 1);
        }
      } else {
        multipleFilter.value.push(event);
      }
      fetchData();
    };

    const linkClicked = (event) => {
      console.log("link clicked");
      console.log(event);
      getPucDocs(event.value.pucDocketNumber);

    };

    const getPucDocs = (enteredValue) => {
      console.log("GET PUC DOCS");
      axios
        .get(CONFIG_ENDPOINT.PUCLINKS + "?docketNumber=" + enteredValue)
        .then((response) => {
          openDialog(enteredValue, response.data);
        })
        .catch((err) => {
          // writing this invalid scenario if the backend service gives 404
          console.log(err);
        });
    };

    const openDialog = (docketValue, data) => {
      console.log("OPEN DIALOG")
      dialogComponent.value.open(docketValue, data).then((resolve) => {
        if (resolve) {
          console.log("resolved");
        }
      });
    };

    return {
      linkClicked,
      activeProjects,
      loading,
      headers,
      updatePaginationPerPage,
      pageChange,
      sortUpdate,
      paginationData,
      textFilterChange,
      clearAllFilter,
      multipleFiltersChange,
      multipleFilter,
      lookupData,
      currentFilters,
      exportButtonClicked,
      userData,
      isUserGradeCrossingAccess,
      dialogComponent,
      openDialog,
      getPucDocs,


    };
  },
};
