<template>
  <v-card class="p-64 v-card-text">
    <div class="allProjectsHeading">Manage District Offices</div>
    <v-card class="p-64">
      <VueTable
        :tableData="districts"
        :columnData="columnData"
        :tableHeader="''"
        :showAddRow="false"
        :errorMessage="errorMessage"
        @editClicked="openDialog($event)"
      >
      </VueTable>
    </v-card>
  </v-card>
  <DialogComponent
    ref="districtOfficeDialog"
    @updateFlaggerNeeds="handleChangesSaved"
  ></DialogComponent>
</template>

<script>
import DistrictOfficeList from "@/modules/gcmsmain/admin/submenu/scripts/DistrictOfficeList.js";
export default DistrictOfficeList;
</script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>
