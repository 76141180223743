<template>
  <div class="allProjectsHeading">GCMS Tasks</div>
  <v-card class="p-64">
    <FeatureTable
      :headers="headers"
      :items="inventorylist"
      :showExportButton="true"
      :exportFilName="'All Tasks'"
      :showClearFilterButton="true"
      :tableHeader="''"
      :itemsPerPage="paginationData?.items"
      :clickable="true"
      :linkedPagination="true"
      :totalItems="paginationData.total"
      :currentPage="paginationData?.page"
      :lookupData="lookupData"
      :oldMultipleFilter="multipleFilter"
      :isDynamicExport="true"
      :showLegends="true"
      @textFilterChange="textFilterChange"
      @multipleFiltersChange="multipleFiltersChange"
      @clearAllFilter="clearAllFilter"
      @updatePaginationPerPage="updatePaginationPerPage"
      @pageChange="pageChange"
      @sortUpdate="sortUpdate"
      @exportButtonClicked="exportButtonClicked"
      :isRowClickedRouteEvent="true"
      @rowClicked="rowClicked($event)"
    >
    </FeatureTable>
  </v-card>
</template>
<script>
import ViewAllTasks from "@/modules/Viewalltasks/scripts/ViewAllTasks.js";
export default ViewAllTasks;
</script>
<script></script>
<style>
@import "@/assets/css/style.scss";
@import "@/assets/css/main.scss";
</style>
