<template>
  <v-card class="p-64 v-card-text">
    <div class="allProjectsHeading">Assign Districts</div>
    <div v-if="SuccessBannerData">
      <BannerMessage
        v-if="
          SuccessBannerData.bannerType === 'success' &&
          SuccessBannerData.showBanner
        "
        :successBanner="true"
        :message="SuccessBannerData.bannerMessage"
        @hideBanner="hideSuccessBanner"
      ></BannerMessage>
    </div>
    <div v-if="ErrorBannerData">
      <BannerMessage
        v-if="
          ErrorBannerData.bannerType === 'error' && ErrorBannerData.showBanner
        "
        :errorBanner="true"
        :message="ErrorBannerData.bannerMessage"
        :hasList="ErrorBannerData.hasList"
        :errorList="ErrorBannerData.errorList"
        @hideBanner="hideErrorBannerMessage"
      ></BannerMessage>
    </div>
    <v-card class="p-64">
      <FeatureTable
        :headers="headers"
        :items="assignedDistricts"
        :showExportButton="true"
        :exportFilName="'Assign Districts'"
        :showClearFilterButton="true"
        :clickable="true"
        :navigationKey="'mpmsNumber'"
        :itemsPerPage="paginationData?.items"
        :linkedPagination="true"
        :totalItems="paginationData?.total"
        :lookupData="lookupData"
        :oldMultipleFilter="multipleFilter"
        :isDynamicExport="true"
        :noDataAvailableText="noDataAvailableText"
        @updatePaginationPerPage="updatePaginationPerPage"
        @pageChange="pageChange"
        @sortUpdate="sortUpdate"
        @textFilterChange="textFilterChange"
        @multipleFiltersChange="multipleFiltersChange"
        @clearAllFilter="clearAllFilter"
        @exportButtonClicked="exportButtonClicked"
        @otherButtonClicked="otherButtonClicked"
      >
      </FeatureTable>
    </v-card>
  </v-card>
  <DialogComponent ref="AssignDistrictsDialog"></DialogComponent>
  <LoadingOverlay :isLoading="loading"></LoadingOverlay>
</template>

<script>
import AssignDistrics from "@/modules/gcmsmain/admin/submenu/scripts/AssignDistricts.js";
export default AssignDistrics;
</script>
<style>
.container {
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
  padding-top: 20px;
}
</style>
