<template>
  <div class="allProjectsHeading">Conditional Railroad Certifications</div>
  <v-card class="p-64">
    <VueTable
      :tableData="ConditionalRailroadCertifications"
      :columnData="columnData"
      :showAddRow="false"
      :showExportButton="true"
      :errorMessage="errorMessage"
      :exportFilName="'Conditional Railroad Certifications'"
      @linkClicked="linkClicked($event)"
    >
    </VueTable>
  </v-card>
</template>
  <script>
import ConditionalRailroadCertifications from "@/modules/manageproject/scripts/ConditionalRailroadCertifications.js";
export default ConditionalRailroadCertifications;
</script>
  
  <style>
@import "@/assets/css/style.scss";
</style>
  