import { CONFIG_ENDPOINT } from "@/constants/Endpoints";import sharedUserService from "@/modules/shared/service/sharedUserService";
import axios from "axios";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
export const userRoleBasedAccessStore = defineStore(
  "userRoleBasedAccess",
  () => {
    const state = {
      isCreateAccess: ref(false),
      error: ref(null),
      isLoading: ref(false),
    };

    const getUserData = {
      userData: computed(() => {
        return sharedUserService.getSharedData();
      }),
    };

    const checkUserAccess = async (inputFeatureCode) => {     
      let url = CONFIG_ENDPOINT.GCMS_MANAGEUSER_ROLE;
      return await axios
        .get(url + "requestPage=" + inputFeatureCode)
        .then((response) => {         
          return response.data.data;
        })
        .catch((err) => {
          console.log(err);
          return false;
        });
    };
    return {
      state,
      getUserData,
      checkUserAccess,
    };
  }
);
