<template>
  <v-app>
    <v-main class="external-v-main">
      <GcmsMainPage
        external
        :mpmsNumber="mpmsNumber"
        :docType="docType"
        :railRoadID="railRoadID"
        :banner="banner"
        :headerMessage="headerMessage"
      />
    </v-main>
  </v-app>
</template>
<script>
import GcmsMainPage from "@/views/GcmsMain.vue";
import CryptoJS from "crypto-js";
export default {
  name: "ExternalLanding",
  components: { GcmsMainPage },
  setup() {
    const headerMessage = "External Header";
    const urlParams = new URLSearchParams(window.location.search);
    const encryptedMpmsNumber = urlParams.get("mpmsNumber");
    const encryptedDocType = urlParams.get("docType");
    const encryptedRailroadId = urlParams.get("railRoadID");
    const encryptedBanner = urlParams.get("banner");
    const decryptMpmsNumber = (strToDecrypt) => {
      const secret = "PD_GCMS_EMAIL_ENCRYPTION";
      if (strToDecrypt) {
        try {
          const key = CryptoJS.enc.Utf8.parse(secret);
          const decodedBytes = CryptoJS.AES.decrypt(strToDecrypt, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
          });
          const decryptedString = decodedBytes.toString(CryptoJS.enc.Utf8);
          return decryptedString;
        } catch (error) {
          console.error("Error while decrypting:", error);
          return null;
        }
      }
      return null;
    };
    const mpmsNumber = decryptMpmsNumber(encryptedMpmsNumber);
    const docType = decryptMpmsNumber(encryptedDocType);
    const railRoadID = decryptMpmsNumber(encryptedRailroadId);
    console.log("encryptedBanner", encryptedBanner);
    const banner = encryptedBanner;
    console.log("banner", banner);
    return {
      mpmsNumber,
      docType,
      railRoadID,
      banner,
      headerMessage,
    };
  },
};
</script>