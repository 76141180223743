import useDateField from "@/composables/dateField.js";
import useIconsFunctions from "@/composables/projectIdentifiersIcons";
import { DROPDOWN_NO_DATA } from "@/constants/displayTexts";
import ExpandedTable from "@/modules/shared/components/ExpandedTable.vue";
import LoadingOverlay from "@/modules/shared/components/LoadingOverlay.vue";
import router from "@/router";
import { useProjectServiceStore } from "@/stores/projectService";
import axios from "axios";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { CONFIG_ENDPOINT } from "../../../constants/Endpoints";

export default {
  components: { ExpandedTable, LoadingOverlay },
  title: "flaggerActivityAdmin",
  emits: ["linkClicked"],
  setup() {
    const projectServiceStore = useProjectServiceStore();
    const { getIdentifierIcon } = useIconsFunctions();
    const subHeaders = ref([
      {
        columnWidth: "10",
        name: "DOT#",
        key: "dotnumber",
        isLink: true,
        alignLeft: false,
      },
      { columnWidth: "10", name: "Owner", key: "xingownr", alignLeft: true },
      { columnWidth: "10", name: "Operator", key: "operator", alignLeft: true },
      { columnWidth: "5 !important", name: "Region", key: "region" },
      {
        columnWidth: "5",
        name: "#Tracks",
        key: "noactivetracks",
        alignLeft: true,
      },
      {
        columnWidth: "10",
        name: "Location",
        key: "location",
        isLink: true,
        alignLeft: true,
      },
      {
        columnWidth: "10",
        name: "Activity",
        key: "activitynames",

        alignLeft: true,
      },
      {
        columnWidth: "10",
        name: "#Flaggers",
        key: "flaggernumber",
        alignLeft: true,
      },
      {
        columnWidth: "10",
        name: "#Flagger Days",
        key: "flaggerdays",
        alignLeft: true,
      },
    ]);
    const dataHeaders = ref([
      { title: "", key: "data-table-expand", fixed: true },

      {
        title: "",
        fixed: true,
        key: "projectIdentifier",
        filter: [],
        filterType: "multi-select",
        filterSort: "number",
        sortType: "text",
        columnType: "icon",
        icon: "projectIdentifierIcon",
        columnWidth: "150px",
      },
      {
        title: "MPMS#",
        fixed: true,
        filter: null,
        filterType: "single-auto-complete",
        sortType: "number",
        key: "mpmsNumber",
        isLink: true,
      },
      {
        title: "ECMS#",
        fixed: true,
        filter: [],
        filterType: "single-auto-complete",
        sortType: "number",
        key: "ecmsNumber",
      },
      {
        title: "Total Flaggers",
        key: "totalFlaggers",
        hideFilter: true,
        sortType: "text",
        columnWidth: "50px",
      },
      {
        title: "Total Flagger Days",
        hideFilter: true,
        sortType: "text",
        key: "totalFlaggerDays",
        columnWidth: "150px",
      },
      {
        title: "Construction Start Date",

        filter: [],
        filterType: "multi-select",
        filterSort: "date",
        filterWith: "yearMonth",
        sortOrder: "asc",
        sortType: "date",
        key: "consStartDate",
      },
      {
        title: "Phys Work Cmplt Date",
        filter: [],
        filterType: "multi-select",
        filterSort: "date",
        filterWith: "yearMonth",
        sortOrder: "asc",
        sortType: "date",
        key: "completeDate",
      },
      {
        title: "Project Class",
        key: "projectClass",
        filter: [],
        filterType: "multi-select",
        filterSort: "number",
        sortType: "text",
      },
      {
        title: "SR",
        key: "stateRoute",
        filter: [],
        filterType: "multi-select",
        filterSort: "number",
        sortType: "text",
      },
      {
        title: "SEC",
        key: "srSection",
        filter: [],
        filterType: "multi-select",
        filterSort: "number",
        sortType: "text",
      },
      {
        title: "District",
        key: "displayDistrict",
        filter: [],
        filterType: "multi-select",
        filterSort: "number",
        sortType: "text",
      },
      {
        title: "County",
        key: "county",
        filter: [],
        filterType: "auto-complete",
        filterSort: "number",
        sortType: "text",
      },
      {
        title: "Agreement#",
        key: "agrNumber",
        filter: [],
        filterType: "multi-select",
        filterSort: "number",
        sortType: "text",
      },
      {
        title: "Agreement Status",
        key: "agrStatus",
        filter: [],
        filterType: "multi-select",
        filterSort: "number",
        sortType: "text",
      },
      {
        title: "ECMS Advert Date",
        key: "ecmsDate",
        filter: [],
        filterType: "multi-select",
        filterSort: "date",
        filterWith: "yearMonth",
        sortOrder: "asc",
        sortType: "date",
      },
      {
        title: "Let Date",
        key: "letDate",
        filter: [],
        filterType: "multi-select",
        filterSort: "date",
        filterWith: "yearMonth",
        sortOrder: "asc",
        sortType: "date",
      },
      {
        title: "NTP Date",
        key: "ntpDate",
        filter: [],
        filterType: "multi-select",
        filterSort: "date",
        filterWith: "yearMonth",
        sortOrder: "asc",
        sortType: "date",
      },
    ]);
    const route = useRoute();
    const expanded = ref([]);
    const data = ref([]);
    const lookupData = ref(null);
    const loading = ref(true);
    const resetActiveKey = ref(false);

    const paginationData = ref({
      page: 1,
      items: 15,
      total: 0,
    });
    const sortData = ref({
      key: "consStartDate",
      order: "asc",
    });
    const textFilter = ref([]);
    const multipleFilter = ref([]);
    const railroadList = ref([]);
    const selectedRailroad = ref(null);
    const selectedRailroadLabel = ref(null);
    onMounted(async () => {
      loading.value = true;
      await retrieveActiveRailroadList();
    });

    async function retrieveActiveRailroadList() {
      let response = projectServiceStore.activeRailroadList;
      if (!response) {
        await projectServiceStore.retrieveActiveRailroadList();
        response = projectServiceStore.activeRailroadList;
      }
      railroadList.value = response?.data;
      loading.value = false;
    }

    async function fetchTableLookupData(Filter) {
      await axios
        .post(
          CONFIG_ENDPOINT.MANAGE_FLAGGER_AND_RAILROADS_GET_TABLE_LOOKUP +
            "?railroad=" +
            selectedRailroad.value,
          Filter
        )
        .then((res) => {
          lookupData.value = res.data;
        });
    }

    async function fetchDataHelper(res,Filter){
      await fetchTableLookupData(Filter);
      if (res.data?.projectFlaggerNeedsSummaryDTO.length) {
        let tableData = res.data?.projectFlaggerNeedsSummaryDTO;
        tableData?.forEach((dataItem) => {
          let icons = "";
          dataItem?.projectIdentifier?.forEach((icon) => {
            icons = icons + getIdentifierIcon(icon);
          });
          dataItem.projectIdentifierIcon = icons;
          dataItem.letDate = setDate(dataItem?.letDate);
          dataItem.consStartDate = setDate(dataItem?.consStartDate);
          dataItem.completeDate = setDate(dataItem?.completeDate);
          dataItem.ecmsDate = setDate(dataItem?.ecmsDate);
          dataItem.ntpDate = setDate(dataItem?.ntpDate);
          dataItem?.flaggerNeeds?.forEach(
            (flagger) =>
              (flagger.activitynames = flagger?.activitynames?.join(", "))
          );
          data.value = tableData;
        });
        dataHeaders.value.forEach((header, index) => {
          dataHeaders.value[index] = {
            ...header,
            sortable: !checkFieldDisabled(header.key),
          };
        });
      } else {
        data.value = [];
      }
    }
    async function fetchData() {
      let Filter = {
        mpmsNumber: null,
        ecmsNumber: null,
        totalFlaggers: null,
        totalFlaggerDays: null,
        consStartDate: null,
        completeDate: null,
        projectClass: null,
        projectIdentifier: null,
        srSection: null,
        stateRoute: null,
        displayDistrict: null,
        county: null,
        agrNumber: null,
        agrStatus: null,
        ecmsDate: null,
        letDate: null,
        ntpDate: null,
      };
      let filterText =
        "?page=" +
        (paginationData.value.page - 1) +
        "&size=" +
        paginationData.value.items +
        "&railroad=" +
        selectedRailroad.value;
      if (sortData.value) {
        filterText =
          filterText +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }
      textFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key) && filter.value) {
          Filter[filter.key] = filter.value;
        }
      });
      multipleFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key) && filter.value) {
          Filter[filter.key] = filter.value;
        }
      });

      axios
        .post(
          CONFIG_ENDPOINT.MANAGE_FLAGGER_AND_RAILROADS_GET + filterText,
          Filter
        )
        .then(async (res) => {

          await fetchDataHelper(res,Filter);
         
        })
        .catch((err) => {
          data.value = [];
        });
    }
    const checkFieldDisabled = (key) => {
      if (key === "projectIdentifier") {
        return true;
      }
      if (
        lookupData.value[key]?.length === 1 &&
        lookupData.value[key][0] === null
      ) {
        return true;
      }
      return !lookupData.value[key]?.length;
    };
    const setDate = (date) => {
      return date ? getDate(date) : date;
    };
    const { getFormattedDateStringNoTime } = useDateField();
    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };
    const expandRow = (item, event) => {
      if (event.isExpanded) {
        let index = expanded.value.findIndex((i) => i === item);
        expanded.value.splice(index, 1);
      } else {
        expanded.value.push(item.name);
      }
    };

    const updatePaginationPerPage = (event) => {
      paginationData.value.page = 1;
      paginationData.value.items = event;
      fetchData();
    };
    const pageChange = (event) => {
      paginationData.value.page = event;
      fetchData();
    };
    const sortUpdate = (event) => {
      if (event.length) {
        sortData.value = {
          key: event[0].key,
          order: event[0].order,
        };
      } else {
        sortData.value = null;
      }
      fetchData();
    };
    const clearAllFilter = (event) => {
      resetActiveKey.value = true;
      resetAllHeaders();
      textFilter.value = [];
      multipleFilter.value = [];
      fetchData();
    };
    const updateResetActiveKeyFlag = (event) => {
      resetActiveKey.value = false;
    };
    const resetAllHeaders = () => {
      subHeaders.value = [
        {
          name: "DOT#",
          key: "dotnumber",
          isLink: true,
          alignLeft: true,
          columnWidth: "15",
        },
        { name: "Owner", key: "xingownr", alignLeft: true, columnWidth: "15" },
        {
          name: "Operator",
          key: "operator",
          alignLeft: true,
          columnWidth: "15",
        },
        { name: "Region", key: "region", columnWidth: "5" },
        {
          name: "#Tracks",
          key: "noactivetracks",
          alignLeft: true,
          columnWidth: "5",
        },
        {
          name: "Location",
          key: "location",
          isLink: true,
          alignLeft: true,
          columnWidth: "10",
        },
        {
          name: "Activity",
          key: "activitynames",
          alignLeft: true,
          columnWidth: "15",
        },
        {
          name: "#Flaggers",
          key: "flaggernumber",
          alignLeft: true,
          columnWidth: "5",
        },
        {
          name: "#Flagger Days",
          key: "flaggerdays",
          columnWidth: "5",
          alignLeft: true,
        },
      ];
      dataHeaders.value = [
        { title: "", key: "data-table-expand" },
        {
          title: "",
          key: "projectIdentifier",
          filter: [],
          filterType: "multi-select",
          filterSort: "number",
          sortType: "text",
          columnType: "icon",
          icon: "projectIdentifierIcon",
          columnWidth: "150px",
        },
        {
          title: "MPMS#",
          filter: null,
          filterType: "single-auto-complete",
          sortType: "number",
          key: "mpmsNumber",
          isLink: true,
        },
        {
          title: "ECMS#",
          filter: [],
          filterType: "single-auto-complete",
          sortType: "number",
          key: "ecmsNumber",
        },
        {
          title: "#Flaggers",
          key: "totalFlaggers",
          hideFilter: true,
          sortType: "text",
          columnWidth: "50px",
        },
        {
          title: "#Flagger Days",
          hideFilter: true,
          sortType: "text",
          key: "totalFlaggerDays",
          columnWidth: "50px",
        },
        {
          title: "Construction Start Date",
          filter: [],
          filterType: "multi-select",
          filterSort: "date",
          filterWith: "yearMonth",
          sortOrder: "asc",
          sortType: "date",
          key: "consStartDate",
          columnWidth: "120px",
        },
        {
          title: "Phys Work Cmplt Date",
          filter: [],
          filterType: "multi-select",
          filterSort: "date",
          filterWith: "yearMonth",
          sortOrder: "asc",
          sortType: "date",
          key: "completeDate",
          columnWidth: "120px",
        },
        {
          title: "Project Class",
          key: "projectClass",
          filter: [],
          filterType: "multi-select",
          filterSort: "number",
          sortType: "text",
          columnWidth: "160px",
        },
        {
          title: "SR",
          key: "stateRoute",
          filter: [],
          filterType: "multi-select",
          filterSort: "number",
          sortType: "text",
        },
        {
          title: "SEC",
          key: "srSection",
          filter: [],
          filterType: "multi-select",
          filterSort: "number",
          sortType: "text",
        },
        {
          title: "District",
          key: "displayDistrict",
          filter: [],
          filterType: "multi-select",
          filterSort: "number",
          sortType: "text",
          columnWidth: "50px",
        },
        {
          title: "County",
          key: "county",
          filter: [],
          filterType: "auto-complete",
          filterSort: "number",
          sortType: "text",
        },
        {
          title: "Agreement#",
          key: "agrNumber",
          filter: [],
          filterType: "multi-select",
          filterSort: "number",
          sortType: "text",
          columnWidth: "150px",
        },
        {
          title: "Agreement Status",
          key: "agrStatus",
          filter: [],
          filterType: "multi-select",
          filterSort: "number",
          sortType: "text",
          columnWidth: "150px",
        },
        {
          title: "ECMS Advert Date",
          key: "ecmsDate",
          filter: [],
          filterType: "multi-select",
          filterSort: "date",
          filterWith: "yearMonth",
          sortOrder: "asc",
          sortType: "date",
          columnWidth: "120px",
        },
        {
          title: "Let Date",
          key: "letDate",
          filter: [],
          filterType: "multi-select",
          filterSort: "date",
          filterWith: "yearMonth",
          sortOrder: "asc",
          sortType: "date",
          columnWidth: "120px",
        },
        {
          title: "NTP Date",
          key: "ntpDate",
          filter: [],
          filterType: "multi-select",
          filterSort: "date",
          filterWith: "yearMonth",
          sortOrder: "asc",
          sortType: "date",
          columnWidth: "120px",
        },
      ];
    };
    const textFilterChange = (event) => {
      let index = textFilter.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value) {
          textFilter.value[index] = event;
        } else {
          textFilter.value.splice(index, 1);
        }
      } else {
        textFilter.value.push(event);
      }
      fetchData();
    };
    const multipleFiltersChange = (event) => {
      let index = multipleFilter.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value?.length) {
          multipleFilter.value[index] = event;
        } else {
          multipleFilter.value.splice(index, 1);
        }
      } else {
        multipleFilter.value.push(event);
      }
      fetchData();
    };
    const linkClicked = (event) => {
      window.scrollTo(0, 0);
      router.push({ path: `/GCMS/` + event.value });
    };
    const updateRailroad = (event) => {
      selectedRailroadLabel.value = railroadList.value?.filter(
        (railroad) => railroad.railroadCompanyId === event
      )?.[0]?.name;
      clearAllFilter("");
    };
    const getDropdownItemsMaxLength = (key, sortType) => {
      let list = railroadList.value?.map((item) => item?.name.length);
      return Math.max(...list) * 8 <= 280
        ? "280px"
        : Math.max(...list) * 8 + "px";
    };

    return {
      linkClicked,
      expanded,
      route,
      singleExpand: true,
      expandRow,
      subHeaders,
      dataHeaders,
      data,
      loading,
      updatePaginationPerPage,
      pageChange,
      sortUpdate,
      paginationData,
      textFilterChange,
      clearAllFilter,
      multipleFiltersChange,
      lookupData,
      railroadList,
      selectedRailroad,
      updateRailroad,
      setDate,
      multipleFilter,
      DROPDOWN_NO_DATA,
      getDropdownItemsMaxLength,
      selectedRailroadLabel,
      resetActiveKey,
      updateResetActiveKeyFlag,
    };
  },
};
