import useDateField from "@/composables/dateField.js";import useIconsFunctions from "@/composables/projectIdentifiersIcons";
import useSharedFunctions from "@/composables/shared";
import FeatureTable from "@/modules/shared/components/FeatureTable.vue";
import axios from "axios";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { CONFIG_ENDPOINT } from "../../../constants/Endpoints";
export default {
  name: "App",
  components: { FeatureTable },
  setup() {
    const router = useRouter();
    const { getIdentifierIcon } = useIconsFunctions();
    const inventorylist = ref([]);
    const loading = ref(true);
    const headers = ref([
      {
        title: "",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        key: "projectIdentifier",
        columnType: "icon",
        icon: "projectIdentifierIcon",
        sortable: false,
        Width: "8%!important",
      },
      {
        title: "MPMS#",
        filter: null,
        filterType: "single-auto-complete",
        sortType: "number",
        key: "mpmsNumber",
        isLink: true,
        Width: "6%!important",
      },
      {
        title: "Project Title",
        filterType: "single-auto-complete",
        sortType: "text",
        key: "projectTitle",
        Width: "8%!important",
      },
      {
        title: "SR",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        key: "stateRoute",
        width: "4%!important",
      },
      {
        title: "SEC",
        key: "srSection",
        width: "4%!important",
      },
      {
        title: "District",
        filter: [],
        filterType: "multi-select",
        filterSort: "number",
        sortType: "text",
        key: "district",
        width: "2%!important",
      },
      {
        title: "County ",
        filter: [],
        filterType: "auto-complete",
        filterSort: "text",
        sortType: "text",
        key: "county",
        Width: "8%!important",
      },
      {
        title: "Task",
        filter: [],
        filterType: "auto-complete",
        filterSort: "text",
        sortType: "text",
        key: "taskName",
        Width: "4%important",
      },
      {
        title: "Status ",
        filter: [],
        filterType: "multi-select",
        filterSort: "text",
        sortType: "text",
        key: "projectStatus",
        Width: "4%",
      },
      {
        title: "Due Date",
        sortOrder: "asc",
        sortType: "date",
        key: "dueDate",
        columnType: "icon",
        textWithIcon: true,
        icon: "dueDateIconKey",
        width: "11%!important",
      },
      {
        title: "Assigned Team Member",
        key: "assignedTeamMember",
        Width: "10%!important",
      },
      {
        title: "Days Remaining",
        key: "daysRemaining",
        width: "5%!important",
        sortable: false,
      },
    ]);
    const paginationData = ref({
      page: 1,
      items: 15,
      total: 0,
    });
    const sortData = ref({
      key: "",
      order: "",
    });
    const textFilter = ref([]);
    const multipleFilter = ref([]);
    const lookupData = ref(null);
    const { getFormattedDateStringNoTime } = useDateField();
    const currentFilters = ref(null);
    const { ExportToCSV } = useSharedFunctions();
    const getDate = (date) => {
      return getFormattedDateStringNoTime(date);
    };
    onMounted(async () => {
      await fetchData();
    });
    const getLookupData = (filterText, Filter) => {
      axios
        .post(
          CONFIG_ENDPOINT.GCMS_MAIN_VIEW_ALL_TASKS_LOOKUP_DATA + filterText,
          Filter
        )
        .then((response) => {
          lookupData.value = response.data;

          headers.value.forEach((header, index) => {
            headers.value[index] = {
              ...header,
              sortable: !checkFieldDisabled(header.key, header?.filterType),
            };
          });
        })
        .catch((err) => {
          lookupData.value = [];
        });
    };
    async function fetchData() {
      let Filter = {
        projectIdentifier: null,
        mpmsNumber: null,
        projectTitle: null,
        stateRoute: null,
        srSection: null,
        district: null,
        county: null,
        status: null,
        projectStatus: null,
        letDate: null,
        rrCoordinator: null,
      };
      textFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key) && filter.value) {
          if (filter.key == "projectTitle") {
            Filter[filter.key] = [filter.value];
          } else {
            Filter[filter.key] = filter.value;
          }
        }
      });
      multipleFilter.value?.forEach((filter) => {
        if (Object.keys(Filter).includes(filter.key) && filter.value) {
          if (filter.key == "projectTitle") {
            Filter[filter.key] = [filter.value];
          } else {
            Filter[filter.key] = filter.value;
          }
        }
      });
      let filterText =
        "?page=" +
        (paginationData.value.page - 1) +
        "&size=" +
        paginationData.value.items;
      if (sortData.value) {
        filterText =
          filterText +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }
      axios
        .post(
          CONFIG_ENDPOINT.GCMS_MAIN_VIEW_ALL_TASKS_PROJECTS + filterText,
          Filter
        )
        .then((response) => {
          setViewAllTasksData(response);
          currentFilters.value = Filter;
          getLookupData(filterText, Filter);
        })
        .catch((err) => {
          console.error("Fetch data error: ", err);
          inventorylist.value = [];
          paginationData.value = {
            page: 1,
            items: paginationData.value.items,
            total: 0,
          };
        });

      const setViewAllTasksData = (response) => {
        loading.value = false;
        let data = response.data.data;
        paginationData.value.total = response.data.totalElements;
        data.forEach((item) => {
          return { ...item, ...setViewAllItemData(item) };
        });
        inventorylist.value = data;
      };

      const setViewAllItemData = (item) => {
        let icons = "";
        item?.projectIdentifier?.forEach((icon) => {
          icons = icons + getIdentifierIcon(icon);
        });
        item.projectIdentifierIcon = icons;
        item.dueDate = getDate(item.dueDate);
        item.dueDateIconKey = getDateIcon(item.dueDateIcon);
        headers.value.forEach((header) => {
          if (item[header.key] === null) {
            item[header.key] = "";
          }
        });
        return item;
      };

      const getDateIcon = (icon) => {
        if (icon === "Red Bell") {
          return "<i class='tableIcons fas fa-bell' aria-hidden='true' style='color: #C70000;'></i>";
        } else if (icon === "Yellow Clock") {
          return "<i class='tableIcons far fa-clock circle-icon' aria-hidden='true'></i>";
        } else {
          return "";
        }
      };
    }
    const exportButtonClicked = () => {
      let filterText = "?page=" + 0 + "&size=" + paginationData.value.total;
      if (sortData.value) {
        filterText =
          filterText +
          "&sort=" +
          sortData.value.key +
          "&direction=" +
          sortData.value.order;
      }
      axios
        .post(
          CONFIG_ENDPOINT.GCMS_MAIN_VIEW_ALL_TASKS_PROJECTS + filterText,
          currentFilters.value
        )
        .then((response) => {
          setExportViewAllTasksData(response);
        })
        .catch((err) => {
          console.error("Export error: ", err);
        });
    };
    const setExportViewAllTasksData = (response) => {
      loading.value = false;
      let exportDataList = response.data.data;
      exportDataList.forEach((item) => {
        let icons = "";
        item?.projectIdentifier?.forEach((icon) => {
          icons = icons + getIdentifierIcon(icon);
        });
        item.projectIdentifierIcon = icons;
        item.dueDate = getDate(item.dueDate);
        item.dueDateIconKey = "";
        headers.value.forEach((header) => {
          if (item[header.key] === null) {
            item[header.key] = "";
          }
        });
      });
      ExportToCSV(headers.value, exportDataList, "All Tasks");
    };
    const checkFieldDisabled = (key, filterType) => {
      if (
        key === "projectIdentifier" ||
        key === "daysRemaining" ||
        key === "projectTitle"
      ) {
        return true;
      }
      if (filterType != "auto-complete" || filterType != "multi-select") {
        return false;
      }
      return !lookupData.value[key]?.length;
    };
    const updatePaginationPerPage = (event) => {
      paginationData.value.page = 1;
      paginationData.value.items = event;
      fetchData();
    };
    const pageChange = (event) => {
      paginationData.value.page = event;
      fetchData();
    };
    const sortUpdate = (event) => {
      sortData.value = {
        key: event[0].key,
        order: event[0].order,
      };
      fetchData();
    };

    const clearAllFilter = (event) => {
      textFilter.value = [];
      multipleFilter.value = [];
      fetchData();
    };
    const textFilterChange = (event) => {
      let index = textFilter.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value) {
          textFilter.value[index] = event;
        } else {
          textFilter.value.splice(index, 1);
        }
      } else {
        textFilter.value.push(event);
      }
    };
    const multipleFiltersChange = (event) => {
      let index = multipleFilter?.value?.findIndex((filter) => {
        return filter.key === event.key;
      });
      if (index >= 0) {
        if (event.value?.length) {
          multipleFilter.value[index] = event;
        } else {
          multipleFilter.value.splice(index, 1);
        }
      } else {
        multipleFilter.value.push(event);
      }
    };
    const rowClicked = (event) => {
      window.scrollTo(0, 0);
      if (event.item?.navigateTo) {
        router.push({
          path: event.item?.navigateTo,
        });
      } else {
        router.push({
          path: `/GCMS/${selectedItem.value["mpmsNumber"]}`,
        });
      }
    };

    return {
      headers,
      inventorylist,
      loading,
      updatePaginationPerPage,
      pageChange,
      paginationData,
      sortUpdate,
      multipleFiltersChange,
      textFilterChange,
      lookupData,
      clearAllFilter,
      multipleFilter,
      exportButtonClicked,
      rowClicked,
    };
  },
};
